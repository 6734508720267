export enum ContentTypes {
  TUTORIAL = 'interactiveTutorial',
  TOUR_DEPRECATED = 'pointsOfInterest',
  COLLECTION = 'Collection',
  LEGACY_TUTORIAL = 'legacyInteractiveTutorial',
  LEGACY_TOUR = 'legacyPointsOfInterest',
  TOUR = 'tour',
  DELETED = 'deletedReference',
}

export enum HomeTabs {
  COLLECTIONS,
  ANSWERS,
}
