import { ReactElement, useState } from 'react';
import { Grid, IconButton, Tooltip, Menu } from '@mui/material';
import { MoreVert } from '@mui/icons-material';

type Props = {
  actions?: ReactElement[];
};

const MoreActionsMenu: React.FC<Props> = ({ actions }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!actions) return null;

  const open = Boolean(anchorEl);

  return (
    <Grid item>
      <Tooltip arrow title="More actions">
        <IconButton
          onClick={handleClick}
          aria-label="More actions"
          color="primary"
        >
          <MoreVert />
        </IconButton>
      </Tooltip>
      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {actions}
      </Menu>
    </Grid>
  );
};

export default MoreActionsMenu;
