import { FC } from 'react';
import { Icon, MenuItem } from '@mui/material';
import { Translate } from '@mui/icons-material';

import { useTranslateContentEntry } from './hooks';

type Props = {
  contentEntryId: number;
};

const TranslateContentEntry: FC<Props> = ({ contentEntryId }) => {
  const { modal, openModal } = useTranslateContentEntry(contentEntryId);

  return (
    <>
      <MenuItem
        onClick={openModal}
        sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
      >
        <Icon>
          <Translate />
        </Icon>
        {'Translate'}
      </MenuItem>
      {modal}
    </>
  );
};

export default TranslateContentEntry;
