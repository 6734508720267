import {
  Model,
  Factory,
  ModelInstance,
  Response,
  Request,
  belongsTo,
  association,
  Collection,
} from 'miragejs';
import { isMirageCollection } from 'services/utils/type-guards/mock-api';
import { SerializerInterface } from 'miragejs/serializer';
import { isCategory } from 'services/utils/type-guards/collection';
import Schema from 'miragejs/orm/schema';

import { ServerRegistry, ServerWithRegistry } from '../server';
import { applicationSerializer } from '../serializer';

type SharedModelAndFactory = Omit<
  LocalizedContentEntryModel,
  'locale' | 'language'
> & {
  traits?: {
    asCollection?: boolean;
    asInteractiveTutorial?: boolean;
    asPointsOfInterest?: boolean;
    asLegacy?: boolean;
    asDeleted?: boolean;
  };
};

// Model hasMany properties return a Collection of <Model>
export type ModelType = SharedModelAndFactory & {
  contentEntry: ModelInstance<ContentEntryModel>;
  language: ModelInstance<LanguageModel>;
  locale: ModelInstance<LocaleModel>;
};

// Factory methods for hasMany properties take an array of <Model>
type FactoryType = Omit<
  ModelType,
  | 'localeId'
  | 'contentEntryId'
  | 'languageId'
  | 'languageDisplayName'
  | 'languageShortCode'
  | 'localeDisplayName'
> & {
  languageId?: number;
  localeId?: number;
  contentEntry?: ModelInstance<ContentEntryModel>;
};

const extractAttributes = (ce: any): ReferenceAttributes => {
  return {
    deviceIds: (ce.devices ?? ([] as any)).models.map(
      ({ id }: DeviceModel) => id
    ),
    deviceTypeIds: (ce.deviceTypes ?? ([] as any)).models.map(
      ({ id }: DeviceModel) => id
    ),
    manufacturerIds: (ce.manufacturers ?? ([] as any)).models.map(
      ({ id }: DeviceModel) => id
    ),
    osIds: (ce.operatingSystems ?? ([] as any)).models.map(
      ({ id }: DeviceModel) => id
    ),
    osReleaseIds: (ce.operatingSystemReleases ?? ([] as any)).models.map(
      ({ id }: DeviceModel) => id
    ),
    osVersionIds: (ce.operatingSystemVersions ?? ([] as any)).models.map(
      ({ id }: DeviceModel) => id
    ),
  };
};

export const model = Model.extend<ModelType>({
  id: 0,
  createdAt: '',
  updatedAt: '',
  languageId: 0,
  localeId: 0,
  localeDisplayName: '',
  languageDisplayName: '',
  languageShortCode: '',
  status: 'draft',
  complete: true,
  contributors: [],
  properties: {} as any,
  contentEntryId: -1,
  language: belongsTo<'language'>() as any,
  locale: belongsTo<'locale'>() as any,
  contentEntry: belongsTo<'contentEntry'>() as any,
  deletedAt: null,
  deletedBy: null,
});

export const factory = Factory.extend<FactoryType, ServerWithRegistry>({
  id: (i) => i + 1,
  createdAt: () => '2023-02-19T17:32:56.060Z',
  updatedAt: () => '2023-02-19T17:32:56.060Z',
  deletedAt: null,
  deletedBy: null,
  status: 'draft',
  complete: true,
  contributors: [],
  properties: (i) => ({
    title: `Mock title ${i}`,
    description: `Mock description ${i}`,
    notes: [`Mock note (0) lce (${i})`, `Mock note (1) lce (${i})`],
    steps: [
      {
        media: { id: 0, referenceType: 'MediaEntry' },
        indicators: [{ id: 'TAP', type: 'TAP', x: 10, y: 10 }],
        notes: [
          `Mock step (0) note (0) lce (${i})`,
          `Mock step (0) note (1) lce (${i})`,
        ],
        command: `Mock command ${i}`,
      },
      {
        media: { id: 0, referenceType: 'MediaEntry' },
        indicators: [{ id: 'TAP', type: 'TAP', x: 10, y: 10 }],
        notes: [
          `Mock step (1) note (0) lce (${i})`,
          `Mock step (1) note (1) lce (${i})`,
        ],
        command: `Mock command ${i}`,
      },
      {
        media: { id: 0, referenceType: 'MediaEntry' },
        indicators: [{ id: 'TAP', type: 'TAP', x: 10, y: 10 }],
        notes: [
          `Mock step (2) note (0) lce (${i})`,
          `Mock step (2) note (1) lce (${i})`,
        ],
        command: `Mock command ${i}`,
      },
    ],
  }),

  language: association(),

  locale: association(),

  contentEntry: association(),

  afterCreate(localizedContentEntry, server) {
    const { id } = localizedContentEntry;
    // set the locale of the lce to match the language created by the lce
    const languageLocal = server.create('locale', {
      languageId: localizedContentEntry.languageId,
    });
    localizedContentEntry.update({
      locale: languageLocal,
    });
    const {
      traits: {
        asCollection,
        asInteractiveTutorial,
        asPointsOfInterest,
        asLegacy,
        asDeleted,
      } = {},
    } = localizedContentEntry;

    if (asInteractiveTutorial) {
      const contentType = server.schema.findOrCreateBy('contentType', {
        name: asLegacy ? 'legacyInteractiveTutorial' : 'interactiveTutorial',
      });
      const contentEntry = server.create('contentEntry', { contentType });
      localizedContentEntry.update({
        contentEntry: contentEntry as any,
      });
    }

    if (asCollection) {
      const properties: CollectionProperties = {
        title: `Mock title ${id}`,
        description: `Mock description ${id}`,
        items: [
          // Add a random number of categories (at least two)
          ...Array.from(Array(Math.ceil(Math.random() * 10) + 1).keys()).map(
            (i) => ({
              title: `Mock title ${i}`,
              description: `Mock description ${i}`,
              items: Array.from(
                // Each category has a random number of references (at least two)
                Array(Math.ceil(Math.random() * 10) + 1).keys()
              ).map((i) => ({ id: i, referenceType: 'ContentEntry' })),
            })
          ),
          // Add a random number of "uncategorized" references
          ...Array.from(
            Array(Math.ceil(Math.random() * 10)).keys()
          ).map((i) => ({ id: i, referenceType: 'ContentEntry' })),
        ],
      };
      localizedContentEntry.update({ properties });
    }

    if (asPointsOfInterest) {
      const contentType = server.schema.findOrCreateBy('contentType', {
        name: asLegacy ? 'legacyPointsOfInterest' : 'pointsOfInterest',
      });
      const contentEntry = server.create('contentEntry', { contentType });
      localizedContentEntry.update({
        contentEntry: contentEntry as any,
      });
      const properties: PointsOfInterestProperties = {
        title: `Mock title ${id}`,
        description: `Mock description ${id}`,
        pointsOfInterest: [
          {
            pointOfInterest: `Mock POI ${id}`,
            description: `Mock description ${id}`,
            indicator: {
              id: 'HOTSPOT',
              type: 'HOTSPOT',
              x: 50,
              y: 50,
            },
          },
        ],
      };
      localizedContentEntry.update({
        contentEntry: contentEntry as any,
        properties,
      });
    }

    if (asDeleted) {
      localizedContentEntry.update({
        deletedAt: new Date().toDateString(),
      });
    }
  },
});

export const serializer = applicationSerializer.extend({
  serialize(
    localizedContentEntry:
      | Collection<ModelInstance<ModelType>>
      | ModelInstance<ModelType>,
    request: Request
  ) {
    // @ts-expect-error
    // Call serialize method from applicationSerializer first
    const serializedData = applicationSerializer.prototype.serialize.apply(
      this,
      [localizedContentEntry, request]
    );

    // If this is a colleciton of models
    if (isMirageCollection(localizedContentEntry)) {
      return (serializedData as ModelType[]).map((o, i) => {
        return {
          ...o,
          // Ozmo API returns the string name of the client, NOT the client object
          locale: localizedContentEntry.models[i].locale?.name ?? undefined,
          localeDisplayName:
            localizedContentEntry.models[i].locale?.displayName ?? undefined,
          language: localizedContentEntry.models[i].language?.name ?? undefined,
          languageDisplayName:
            localizedContentEntry.models[i].language?.displayName ?? undefined,
          languageShortCode:
            localizedContentEntry.models[i].language?.shortCode ?? undefined,
        };
      });
    }
    // Otherwise it is just a single model
    return {
      ...localizedContentEntry.attrs,
      ...serializedData,
      // Ozmo API returns the string name of the client, NOT the client object
      locale: localizedContentEntry.locale?.name ?? undefined,
      localeDisplayName: localizedContentEntry.locale?.displayName ?? undefined,
      language: localizedContentEntry.language?.name ?? undefined,
      languageDisplayName:
        localizedContentEntry.language?.displayName ?? undefined,
      languageShortCode: localizedContentEntry.language?.shortCode ?? undefined,
    };
  },
});

export const serializeIfExists = (
  localizedContentEntry: ModelInstance<ModelType>[] | undefined,
  request: Request,
  context: {} | SerializerInterface
) => {
  if (localizedContentEntry) {
    return (serializer as any).prototype.serialize.apply(context, [
      localizedContentEntry,
      request,
    ]);
  }
};

const findAndFormatContentEntryForCollection = (
  contentEntryId: number,
  schema: Schema<ServerRegistry>,
  languageId?: number,
  localeId?: number
): LocalizedCollectionReference | MissingLocalizedCollectionReference => {
  const ce = schema.find('contentEntry', contentEntryId.toString());

  if (!ce || !ce.localizedContentEntries) {
    return {
      id: contentEntryId,
    } as any;
  }

  const lce = (ce.localizedContentEntries as Collection<ModelType>).models.find(
    (l) => (languageId ? l.languageId === languageId : l.localeId === localeId)
  );

  if (!lce) {
    return {
      id: contentEntryId,
      createdAt: ce.createdAt,
      updatedAt: ce.updatedAt,
      contentType: ce.contentType!.name,
      contentTypeId: parseInt(ce.contentTypeId as any, 10),
      space: ce.space!.name,
      spaceId: parseInt(ce.spaceId as any, 10),
      topicId: ce.topicId,
      topic: ce.topic?.title,
      topicSlug: ce.topic?.slug,
      localizedContentEntryId: null,
      locale: null,
      localeId: null,
      language: null,
      languageId: null,
      languageShortCode: null,
      status: null,
      complete: false,
      title: null,
      description: null,
      ...extractAttributes(ce),
    };
  }

  return serializeAsLocalizedReference(lce);
};

export const serializeAsLocalizedReference = (
  lce: ModelType
): LocalizedCollectionReference => ({
  id: parseInt(lce.contentEntry.id, 10),
  createdAt: lce.createdAt,
  updatedAt: lce.updatedAt,
  contentType: (lce.contentEntry.contentType as any).name,
  contentTypeId: parseInt(lce.contentEntry.contentTypeId as any, 10),
  space: lce.contentEntry.space!.name,
  spaceId: parseInt(lce.contentEntry.spaceId as any, 10),
  topic: lce.contentEntry.topic?.title,
  topicId: lce.contentEntry.topicId,
  topicSlug: lce.contentEntry.topic?.slug,
  localizedContentEntryId: parseInt(lce.id as any, 10),
  locale: lce.locale.name,
  localeId: parseInt(lce.localeId as any, 10),
  language: lce.language.name,
  languageId: parseInt(lce.languageId as any, 10),
  languageShortCode: lce.language.shortCode,
  status: lce.status,
  complete: lce.complete,
  title: lce.properties?.title,
  description: lce.properties?.description,
  ...extractAttributes(lce.contentEntry),
});

export const serializeAsCollection = (
  lce: ModelType,
  schema: Schema<ServerRegistry>
): LocalizedCollectionModel => ({
  id: parseInt(lce.id as any, 10),
  createdAt: lce.createdAt,
  updatedAt: lce.updatedAt,
  contentEntryId: lce.contentEntryId,
  locale: lce.locale.name,
  localeId: lce.localeId,
  language: lce.language.name,
  languageDisplayName: lce.language.displayName,
  languageShortCode: lce.language.shortCode,
  languageId: lce.languageId,
  alternativeTitle: lce.alternativeTitle ?? null,
  lastPublishedId: lce.lastPublished?.id ?? null,
  status: lce.status,
  contributors: lce.contributors,
  properties: {
    title: lce.properties.title,
    description: lce.properties.description,
    items: lce.properties.items.map((item: CategoryItem | Reference) => {
      if (isCategory(item)) {
        return {
          ...item,
          items: item.items.map((ref) =>
            findAndFormatContentEntryForCollection(
              ref.id,
              schema,
              lce.languageId,
              lce.localeId
            )
          ),
        };
      }

      return findAndFormatContentEntryForCollection(
        item.id,
        schema,
        lce.languageId,
        lce.localeId
      );
    }),
  },
});

export const createRoutes = (server: ServerWithRegistry) => {
  server.get(
    'authoring/content_entries/:contentEntryId/localized_content_entries/:id',
    (schema, request) => {
      const { queryParams } = request;
      const lce = schema.find('localizedContentEntry', request.params.id);
      if (queryParams?.as_collection && lce) {
        return serializeAsCollection(lce, schema);
      }
      return lce;
    }
  );
  server.get(
    'authoring/content_entries/:contentEntryId/localized_content_entries/',
    (schema) => schema.all('localizedContentEntry')
  );

  // update
  server.patch(
    'authoring/content_entries/:contentEntryId/localized_content_entries/:id',
    (schema, request) => {
      const { id } = request.params;
      const body = JSON.parse(request.requestBody);
      // Some extra fields are making into the requests and breaking the Mirage association logic
      // so remove them.  This is safe to do because these are _actually_ set by language_id and locale_id
      delete body.language;
      delete body.locale;

      const localizedContentEntry = schema.find('localizedContentEntry', id);
      if (localizedContentEntry) {
        localizedContentEntry.update(body);
        return localizedContentEntry;
      }
      return new Response(404);
    }
  );

  // update status
  server.patch(
    'authoring/content_entries/:contentEntryId/localized_content_entries/:id/update_status',
    (schema, request) => {
      const { id } = request.params;
      const body = JSON.parse(request.requestBody);
      const localizedContentEntry = schema.find('localizedContentEntry', id);
      if (localizedContentEntry) {
        localizedContentEntry.update(body);
        return localizedContentEntry;
      }

      return new Response(404);
    }
  );

  // restore
  server.post(
    'authoring/content_entries/:contentEntryId/localized_content_entries/:id/restore',
    (schema, request) => {
      const { id } = request.params;
      const localizedContentEntry = schema.find('localizedContentEntry', id);

      if (localizedContentEntry) {
        localizedContentEntry.update({ deletedAt: null });
        return localizedContentEntry;
      }

      return new Response(404);
    }
  );

  // create
  server.post(
    'authoring/content_entries/:contentEntryId/localized_content_entries',
    (schema, request) => {
      const body = JSON.parse(request.requestBody);
      return schema.create('localizedContentEntry', body);
    }
  );

  // batch
  server.post(
    'authoring/batch/localized_content_entries',
    (schema, request) => {
      const { 'Content-Type': contentType } = request.requestHeaders;
      if (contentType === 'application/x-ndjson') {
        return {
          job: 'jobid-123-456',
        };
      }

      return new Response(400);
    }
  );

  server.del(
    'authoring/content_entries/:contentEntryId/localized_content_entries/:id',
    (schema, request) => {
      const localizedContentEntry = schema.find(
        'localizedContentEntry',
        request.params.id
      );
      if (localizedContentEntry) {
        localizedContentEntry.update({
          deletedAt: new Date().toISOString(),
        });
        return new Response(204);
      }
      return new Response(404);
    }
  );

  // translate
  server.post(
    'authoring/content_entries/:contentEntryId/localized_content_entries/:id/translate',
    (schema, request) => {
      const { id } = request.params;
      const localizedContentEntry = schema.find('localizedContentEntry', id);
      if (localizedContentEntry) {
        // return as-is for this purpose
        return localizedContentEntry;
      }

      return new Response(404);
    }
  );
};
