import { FC } from 'react';
import { Stack, Typography } from '@mui/material';

const Complete: FC = () => (
  <Stack spacing={2} alignItems="center" data-testid="translate-success">
    <svg
      width="250"
      height="250"
      viewBox="0 0 250 250"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M136.828 200.67C136.828 202.865 137.7 204.971 139.252 206.523C140.804 208.075 142.91 208.948 145.105 208.948C147.3 208.948 149.406 208.075 150.958 206.523C152.51 204.971 153.382 202.865 153.382 200.67C153.382 198.475 152.51 196.369 150.958 194.817C149.406 193.265 147.3 192.393 145.105 192.393C142.91 192.393 140.804 193.265 139.252 194.817C137.7 196.369 136.828 198.475 136.828 200.67Z"
        stroke="#106A20"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M153.284 134.015C152.957 132.363 153.262 130.656 154.146 129.199C155.03 127.743 156.433 126.635 158.103 126.074C159.773 125.512 161.6 125.536 163.253 126.14C164.907 126.744 166.278 127.888 167.12 129.367C167.273 129.635 167.523 129.842 167.824 129.951C168.125 130.06 168.456 130.064 168.76 129.961L172.608 128.665C172.786 128.605 172.949 128.51 173.087 128.386C173.224 128.263 173.332 128.113 173.404 127.947C173.476 127.781 173.511 127.603 173.506 127.424C173.501 127.244 173.456 127.068 173.375 126.906C172.076 124.331 169.946 122.215 167.294 120.864C164.642 119.514 161.606 118.999 158.625 119.393C155.643 119.788 152.872 121.072 150.712 123.06C148.551 125.048 147.114 127.636 146.609 130.45"
        stroke="#106A20"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M105.182 142.658C105.182 143.745 105.396 144.821 105.812 145.825C106.228 146.83 106.837 147.742 107.606 148.511C108.375 149.279 109.287 149.889 110.291 150.305C111.296 150.721 112.372 150.935 113.459 150.935C114.546 150.935 115.622 150.721 116.627 150.305C117.631 149.889 118.544 149.279 119.312 148.511C120.081 147.742 120.691 146.83 121.106 145.825C121.522 144.821 121.737 143.745 121.737 142.658C121.737 141.571 121.522 140.494 121.106 139.49C120.691 138.486 120.081 137.573 119.312 136.805C118.544 136.036 117.631 135.426 116.627 135.01C115.622 134.594 114.546 134.38 113.459 134.38C112.372 134.38 111.296 134.594 110.291 135.01C109.287 135.426 108.375 136.036 107.606 136.805C106.837 137.573 106.228 138.486 105.812 139.49C105.396 140.494 105.182 141.571 105.182 142.658Z"
        stroke="#106A20"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M139.021 139.621C139.021 140.651 139.224 141.671 139.618 142.622C140.012 143.574 140.59 144.439 141.318 145.167C142.047 145.895 142.911 146.473 143.863 146.867C144.815 147.261 145.835 147.464 146.865 147.464C147.895 147.464 148.915 147.261 149.866 146.867C150.818 146.473 151.683 145.895 152.411 145.167C153.139 144.439 153.717 143.574 154.111 142.622C154.506 141.671 154.708 140.651 154.708 139.621C154.708 138.591 154.506 137.571 154.111 136.619C153.717 135.667 153.139 134.803 152.411 134.074C151.683 133.346 150.818 132.768 149.866 132.374C148.915 131.98 147.895 131.777 146.865 131.777C145.835 131.777 144.815 131.98 143.863 132.374C142.911 132.768 142.047 133.346 141.318 134.074C140.59 134.803 140.012 135.667 139.618 136.619C139.224 137.571 139.021 138.591 139.021 139.621Z"
        stroke="#106A20"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M162.227 181.736C162.227 182.648 162.406 183.55 162.755 184.392C163.104 185.235 163.615 186 164.26 186.644C164.904 187.289 165.67 187.8 166.512 188.149C167.354 188.498 168.257 188.677 169.168 188.677C170.08 188.677 170.982 188.498 171.825 188.149C172.667 187.8 173.432 187.289 174.076 186.644C174.721 186 175.232 185.235 175.581 184.392C175.93 183.55 176.11 182.648 176.11 181.736C176.11 180.824 175.93 179.922 175.581 179.08C175.232 178.238 174.721 177.472 174.076 176.828C173.432 176.183 172.667 175.672 171.825 175.323C170.982 174.974 170.08 174.795 169.168 174.795C168.257 174.795 167.354 174.974 166.512 175.323C165.67 175.672 164.904 176.183 164.26 176.828C163.615 177.472 163.104 178.238 162.755 179.08C162.406 179.922 162.227 180.824 162.227 181.736Z"
        stroke="#106A20"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M195.129 180.857C194.129 182.6 192.715 184.102 190.996 185.248C188.864 186.701 186.345 187.573 183.719 187.768C181.092 187.963 178.459 187.474 176.109 186.353V180.857H179.721C180.619 181.207 181.582 181.387 182.554 181.387C183.526 181.387 184.488 181.207 185.386 180.857C185.953 180.664 186.487 180.396 186.974 180.062C188.429 179.073 189.46 177.629 189.882 175.99C190.303 174.35 190.088 172.623 189.273 171.12C189.129 170.845 189.093 170.531 189.174 170.234C189.254 169.938 189.445 169.677 189.711 169.501L193.05 167.215C193.208 167.114 193.387 167.044 193.575 167.01C193.763 166.976 193.957 166.978 194.144 167.017C194.332 167.055 194.509 167.13 194.664 167.236C194.819 167.342 194.949 167.477 195.046 167.633C196.269 169.645 196.919 171.918 196.933 174.235C196.948 176.551 196.326 178.832 195.129 180.857Z"
        stroke="#106A20"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M131.069 164.892L139.523 194.557"
        stroke="#106A20"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M149.826 190.444L142.454 164.574"
        stroke="#106A20"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M162.044 180.442L147.141 192.644"
        stroke="#106A20"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M153.297 201.868L169.378 188.702"
        stroke="#106A20"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M83.1906 146.755L106.014 139.041"
        stroke="#106A20"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M109.052 149.665L85.0048 157.79"
        stroke="#106A20"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M139.72 144.617L120.969 146.145"
        stroke="#106A20"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M117.634 135.509L140.518 133.646"
        stroke="#106A20"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114.533 245.423V223.428C109.619 225.241 104.22 225.241 99.3058 223.428V245.423"
        stroke="#106A20"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M123.645 145.927C125.176 146.535 126.489 147.588 127.414 148.951C128.339 150.313 128.834 151.922 128.834 153.569V178.222C128.834 179.301 128.621 180.37 128.208 181.367C127.795 182.364 127.19 183.27 126.427 184.033C125.664 184.796 124.758 185.401 123.761 185.814C122.764 186.227 121.695 186.44 120.616 186.44H93.2225C92.1433 186.44 91.0747 186.227 90.0777 185.814C89.0807 185.401 88.1748 184.796 87.4117 184.033C86.6486 183.27 86.0433 182.364 85.6303 181.367C85.2173 180.37 85.0048 179.301 85.0048 178.222V164.527"
        stroke="#106A20"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.7015 186.439H115.137V202.875H98.7015V186.439Z"
        stroke="#106A20"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M117.882 149.663V164.527C117.882 165.98 117.305 167.374 116.277 168.402C115.25 169.429 113.856 170.006 112.403 170.006H101.447C100.727 170.007 100.013 169.866 99.3478 169.591C98.6823 169.316 98.0775 168.913 97.5681 168.404C97.0586 167.895 96.6544 167.291 96.3787 166.626C96.1029 165.96 95.961 165.247 95.961 164.527V154.09"
        stroke="#106A20"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M122.42 202.875C123.296 202.875 124.16 203.085 124.938 203.488C125.716 203.891 126.387 204.475 126.893 205.19C127.399 205.906 127.726 206.732 127.846 207.6C127.967 208.468 127.877 209.353 127.585 210.179C126.074 214.452 123.275 218.151 119.574 220.768C115.873 223.384 111.452 224.789 106.919 224.789C102.387 224.789 97.966 223.384 94.2651 220.768C90.5641 218.151 87.7652 214.452 86.2535 210.179C85.9616 209.353 85.8722 208.468 85.9928 207.6C86.1133 206.732 86.4403 205.906 86.9463 205.19C87.4523 204.475 88.1226 203.891 88.9008 203.488C89.679 203.085 90.5426 202.875 91.4189 202.875H122.42Z"
        stroke="#106A20"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M85.0111 164.527H74.0485C72.9693 164.527 71.9007 164.314 70.9037 163.901C69.9067 163.488 69.0008 162.883 68.2377 162.12C67.4746 161.357 66.8693 160.451 66.4563 159.454C66.0434 158.457 65.8308 157.388 65.8308 156.309V153.566C65.8308 152.487 66.0434 151.419 66.4564 150.422C66.8694 149.425 67.4747 148.519 68.2378 147.756C69.001 146.993 69.9069 146.388 70.9039 145.976C71.9009 145.563 72.9694 145.351 74.0485 145.351H79.5277C80.2475 145.35 80.9604 145.491 81.6256 145.766C82.2909 146.041 82.8954 146.444 83.4047 146.953C83.9139 147.461 84.318 148.066 84.5936 148.73C84.8692 149.395 85.0111 150.108 85.0111 150.828V164.527Z"
        stroke="#106A20"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M140.373 145.37C140.179 145.357 139.984 145.351 139.788 145.351H134.317C133.598 145.351 132.886 145.492 132.221 145.767C131.556 146.042 130.952 146.446 130.443 146.954C129.934 147.463 129.531 148.067 129.255 148.731C128.98 149.396 128.838 150.108 128.838 150.828V164.527H139.797C141.976 164.526 144.065 163.66 145.606 162.119C147.147 160.578 148.012 158.488 148.012 156.309V153.566C148.015 151.426 147.18 149.37 145.686 147.837"
        stroke="#106A20"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M95.9589 112.873C95.4323 112.872 94.911 112.768 94.4247 112.566C93.9384 112.364 93.4968 112.068 93.1249 111.695C92.753 111.322 92.4583 110.88 92.2575 110.393C92.0567 109.906 91.9538 109.384 91.9546 108.858C91.9872 107.598 91.6662 106.354 91.0282 105.268C90.8807 105.012 90.6725 104.798 90.4221 104.642C90.4797 104.676 90.2342 104.843 89.9503 105.268C89.3097 106.353 88.9852 107.596 89.0133 108.856C88.9875 109.902 88.5531 110.898 87.8031 111.628C87.053 112.359 86.0467 112.767 84.9996 112.765C83.9524 112.764 82.9475 112.352 82.1998 111.619C81.452 110.886 81.0208 109.89 80.9984 108.843C80.962 106 81.7574 103.209 83.2865 100.813C84.0127 99.5408 85.0627 98.4836 86.33 97.7489C87.5972 97.0142 89.0365 96.6282 90.5013 96.6301C91.9661 96.6321 93.4043 97.0219 94.6696 97.76C95.9349 98.4981 96.9821 99.5581 97.7049 100.832C99.2267 103.234 100.014 106.028 99.9695 108.871C99.9667 109.932 99.5433 110.95 98.7919 111.7C98.0406 112.45 97.0227 112.872 95.961 112.873H95.9589Z"
        stroke="#106A20"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M117.882 112.872C116.82 112.871 115.801 112.45 115.049 111.7C114.297 110.95 113.873 109.932 113.869 108.87C113.826 106.028 114.613 103.234 116.134 100.832C116.857 99.5578 117.904 98.4979 119.17 97.7599C120.435 97.0219 121.873 96.6321 123.338 96.6301C124.803 96.6282 126.243 97.0141 127.51 97.7488C128.777 98.4834 129.828 99.5405 130.554 100.813C132.082 103.209 132.878 106 132.842 108.843C132.818 109.889 132.385 110.885 131.636 111.617C130.888 112.349 129.882 112.759 128.835 112.759C127.788 112.759 126.782 112.349 126.033 111.617C125.284 110.885 124.852 109.889 124.827 108.843C124.855 107.583 124.529 106.341 123.888 105.257C123.741 105.001 123.533 104.786 123.282 104.631C123.338 104.665 123.094 104.834 122.81 105.259C122.174 106.345 121.854 107.588 121.886 108.847C121.888 109.374 121.786 109.897 121.585 110.384C121.385 110.872 121.09 111.316 120.718 111.69C120.346 112.063 119.905 112.36 119.418 112.563C118.931 112.766 118.409 112.871 117.882 112.872Z"
        stroke="#106A20"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M101.44 140.587V134.93"
        stroke="#106A20"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M110.492 134.931H76.7998C74.6182 134.929 72.5264 134.062 70.9842 132.519C69.442 130.976 68.5757 128.884 68.5757 126.702L68.5757 95.5183M98.5079 74.666H137.039C139.221 74.666 141.314 75.5329 142.857 77.076C144.4 78.6192 145.267 80.7121 145.267 82.8944V126.702C145.269 128.546 144.65 130.336 143.51 131.784"
        stroke="#106A20"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M170.036 141.276C170.036 148.034 172.72 154.515 177.499 159.294C182.278 164.073 188.76 166.758 195.518 166.758C202.276 166.758 208.758 164.073 213.536 159.294C218.315 154.515 221 148.034 221 141.276C221 134.517 218.315 128.036 213.536 123.257C208.758 118.478 202.276 115.793 195.518 115.793C188.76 115.793 182.278 118.478 177.499 123.257C172.72 128.036 170.036 134.517 170.036 141.276Z"
        stroke="#106A20"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M190.364 153.851C189.876 153.851 189.394 153.755 188.944 153.569C188.493 153.382 188.084 153.108 187.74 152.763L180.319 145.342C179.624 144.647 179.233 143.703 179.233 142.719C179.233 141.735 179.624 140.792 180.319 140.096C181.015 139.4 181.959 139.01 182.943 139.01C183.926 139.01 184.87 139.4 185.566 140.096L190.361 144.891L205.471 129.786C205.814 129.434 206.223 129.153 206.676 128.96C207.128 128.768 207.614 128.667 208.105 128.663C208.597 128.66 209.084 128.754 209.539 128.941C209.994 129.127 210.407 129.403 210.755 129.75C211.102 130.098 211.377 130.511 211.564 130.966C211.75 131.421 211.845 131.908 211.841 132.4C211.838 132.891 211.737 133.378 211.544 133.83C211.351 134.282 211.07 134.691 210.717 135.034L192.987 152.761C192.643 153.106 192.234 153.38 191.784 153.567C191.334 153.755 190.851 153.851 190.364 153.851Z"
        stroke="#106A20"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.1906 53.7838C78.7909 53.4669 77.3248 53.6449 76.0416 54.2874C74.7584 54.93 73.7376 55.9973 73.1529 57.3079C72.5681 58.6184 72.4557 60.091 72.8346 61.4752C73.2135 62.8593 74.0603 64.0693 75.231 64.8993C76.4017 65.7293 77.8238 66.1279 79.2554 66.0273C80.6869 65.9267 82.0393 65.3331 83.0824 64.3475C84.1255 63.362 84.7948 62.0454 84.9764 60.6219C85.158 59.1984 84.8407 57.7559 84.0784 56.5401"
        stroke="#106A20"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.0597 53.7367C52.5281 55.3145 53.6042 56.6416 55.0511 57.4261C56.4981 58.2106 58.1974 58.3881 59.7753 57.9196C61.3531 57.4512 62.6803 56.3751 63.4647 54.9282C64.2492 53.4812 64.4267 51.7819 63.9583 50.204C63.4898 48.6262 62.4137 47.299 60.9668 46.5146C59.5198 45.7301 57.8205 45.5526 56.2426 46.021C54.6648 46.4895 53.3377 47.5656 52.5532 49.0125C51.7687 50.4595 51.5912 52.1588 52.0597 53.7367Z"
        stroke="#106A20"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.4881 82.4814C52.5062 83.0148 53.6525 83.2548 54.7991 83.1745C55.9456 83.0941 57.0473 82.6967 57.981 82.0265C58.9148 81.3562 59.6438 80.4397 60.0868 79.3791C60.5298 78.3185 60.6692 77.1557 60.4895 76.0204C60.3098 74.8852 59.8181 73.8222 59.0693 72.9503C58.3204 72.0783 57.344 71.4317 56.2489 71.0826C55.1538 70.7335 53.9833 70.6957 52.8679 70.9733C51.7526 71.251 50.7364 71.8333 49.9329 72.655"
        stroke="#106A20"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.5297 38.4808L53.0795 92.5362"
        stroke="#106A20"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M96.0631 64.8264L46.6419 30.4421"
        stroke="#106A20"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.6511 30.4392C45.9898 30.1651 45.3082 29.9428 44.6125 29.7745C44.1149 32.0042 44.259 34.3292 45.0279 36.4805C45.9166 38.9242 42.0417 40.0872 41.1495 37.632C40.5644 36.0243 40.2584 34.3284 40.2445 32.6175C39.6904 33.3093 39.1589 34.0279 38.6572 34.7626C37.1991 36.8987 33.6134 35.0403 35.0756 32.8967C35.8819 31.7012 36.7548 30.5521 37.6902 29.4548C36.8258 29.5524 35.9624 29.6749 35.1103 29.8421C32.5832 30.3371 31.3883 26.4674 33.9588 25.9637C35.809 25.5938 37.6855 25.3704 39.5709 25.2955C38.921 23.2701 38.4528 21.1908 38.1719 19.0823C37.8117 16.509 41.8568 16.3981 42.2149 18.9575C42.3915 20.2655 42.6504 21.5612 42.99 22.8367C43.8387 21.2935 44.7979 19.8137 45.86 18.4088C47.4243 16.327 50.3926 19.0801 48.8417 21.1433C48.4851 21.6195 48.1419 22.1127 47.812 22.6082C49.0932 22.2811 50.4103 22.1149 51.7327 22.1137C54.3311 22.1059 54.4706 26.1488 51.8595 26.1561C50.6404 26.1643 49.4292 26.3518 48.2648 26.7128C50.1584 27.4942 51.8718 28.6554 53.2993 30.1247C54.9852 31.8567 52.7292 34.5895 50.9374 33.4455"
        stroke="#106A20"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.6186 96.1561C51.683 96.8448 51.9134 97.5076 52.2901 98.0876C52.6669 98.6676 53.1787 99.1477 53.7817 99.4865C54.3847 99.8253 55.0608 100.013 55.7522 100.033C56.4436 100.053 57.1295 99.9051 57.7512 99.6018C73.666 92.7921 88.3316 83.3718 101.142 71.7296C101.677 71.2904 102.096 70.7281 102.366 70.091C102.635 69.454 102.746 68.7611 102.688 68.0718C102.631 67.3826 102.407 66.7175 102.036 66.1337C101.666 65.5498 101.159 65.0647 100.559 64.7197C99.6858 64.2772 98.6978 64.1129 97.7281 64.249C96.7585 64.385 95.8538 64.8147 95.1358 65.4804C83.0444 76.2307 69.3484 85.0268 54.5428 91.551C53.6445 91.9297 52.8833 92.5735 52.3607 93.3964C51.8382 94.2193 51.5793 95.1821 51.6186 96.1561Z"
        stroke="#106A20"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
    <Typography variant="h4" component="p">
      {'Success! Your translations are complete.'}
    </Typography>
  </Stack>
);

export default Complete;
