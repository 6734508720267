import { useActionModal } from 'components/modals';
import { useTranslateContentEntry as useModalActions } from 'scenes/translations/translate-content-entry/hooks';
import TranslateContentEntryModalContent from 'scenes/translations/translate-content-entry/modal';

export const useTranslateContentEntry = (contentEntryId: number) => {
  const {
    onSubmit,
    onRefuse,
    handleSelectTarget,
    languages,
    title,
    slug,
    state,
    targets,
    isLoading,
  } = useModalActions(contentEntryId);

  const modalTitle =
    !isLoading && !title
      ? 'We’re sorry. You cannot translate this answer.'
      : state === 0
      ? 'You are about to translate this answer'
      : '';

  const { modal, openModal } = useActionModal({
    modalContent: (
      <TranslateContentEntryModalContent
        title={title ?? ''}
        slug={slug ?? 'n/a'}
        status={state}
        languages={languages}
        isLoading={isLoading}
        handleSelectTarget={handleSelectTarget}
      />
    ),
    onConfirm: onSubmit,
    onRefuse,
    closeOnConfirm: false,
    modalProps: {
      title: modalTitle,
      maxWidth: 'md',
      confirmButtonText: 'Translate',
      closeButtonText: state > 1 ? 'Close' : 'Cancel',
      disableConfirmButton:
        isLoading || !title || state !== 0 || targets.length === 0,
    },
  });

  return {
    openModal,
    modal,
  };
};
