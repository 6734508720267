import {
  useState,
  useEffect,
  useMemo,
  useCallback,
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEvent,
} from 'react';
import Fuse from 'fuse.js';
import { formatAttributeName } from 'services/ozmo-api/utils/format-attribute-name';
import useOzmoApiService from 'contexts/ozmo-api-service-context';
import { isNode } from 'services/utils/type-guards/is-node';
import { useSearchParams, Location } from 'react-router-dom';
import { ContentTypes } from 'types/enums';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';

import {
  AllowedAttributeKeys,
  AllowedAttributeModel,
  AllowedAttributeModels,
  ApiAttribute,
  AttributeOptionWithKey,
  AttributeOption,
  AttributeOptionColor,
  SearchableAttribute,
  SelectedAttributes,
  AttributesWithValueType,
  StringKeyOf,
  KeywordAttribute,
  AllowedApiAttributeKeys,
} from './types';
import ContentTypeName from './content-type-chip';

/* TYPES */
type FuseSearchArgs<T extends AllowedAttributeModel> = {
  searchTerm: string;
  keys: StringKeyOf<T>[];
  options: AllowedAttributeModel[];
};

type FormatAttributeArgs<T extends AllowedAttributeModel> = {
  key: AllowedAttributeKeys;
  selectedReturnKey: string;
  results: Fuse.FuseResult<T>[] | T[];
  totalCount: number;
  optionNameProperty?: StringKeyOf<T> | 'name';
  optionNameFormatter?: (attributeModel: T) => string;
};

/* CONSTANTS */
const SEARCH_QUERY_LENGTH_REQUIREMENT = 3;
const ATTRIBUTE_OPTION_LIMIT = 15;

/* HELPER FUNCTIONS */
const formatOsReleaseName = (release: OperatingSystemReleaseModel) =>
  `${release.operatingSystemName} ${release.name}`;
const formatOsVersionName = (version: OperatingSystemVersionModel) =>
  `${version.operatingSystem} ${version.name}`;
const formatDeviceName = (device: DeviceModel) =>
  formatAttributeName(device.trackingName);
const formatLanguageName = (language: LanguageModel) => language.displayName;
const formatTopicName = (topic: TopicModel) => topic.slug;

const formatStartingAttributes = (
  attributes: Record<string, any>
): Record<string, any[]> => {
  const newAttrs = {
    Device: attributes.deviceIds || [],
    Manufacturer: attributes.manufacturerIds || [],
    DeviceType: attributes.deviceTypeIds || [],
    OperatingSystem: attributes.operatingSystemIds || [],
    OperatingSystemRelease: attributes.operatingSystemReleaseIds || [],
    OperatingSystemVersion:
      attributes.operatingSystemVersionIds || attributes.osVersionIds || [],
  };
  return newAttrs;
};

const getOptionName = <T extends {}>(
  option: T,
  optionNameProperty: StringKeyOf<T> | 'name' = 'name',
  optionNameFormatter?: (attributeModel: T) => string
): string => {
  if (optionNameFormatter) {
    return optionNameFormatter(option);
  }

  if (optionNameProperty in option) {
    // can't infer the optionNameProperty to index option, but the "in" check above will
    // check that the property does exist in option
    // @ts-ignore
    return option[optionNameProperty];
  }

  // shouldn't ever get to this point, but gotta return something.
  // this would mean a formatter wasn't given, or the property given can't be
  // found in the option.
  return '';
};

const getContentTypeChipColor = (name: string): AttributeOptionColor => {
  switch (name) {
    case ContentTypes.TUTORIAL:
    case ContentTypes.TOUR_DEPRECATED:
    default:
      return {
        chipColor: 'contentTutorial',
        chipColorUsed: 'contentTutorialUsed',
      };
    case ContentTypes.LEGACY_TUTORIAL:
    case ContentTypes.LEGACY_TOUR:
      return {
        chipColor: 'legacyContentChip',
        chipColorUsed: 'legacyContentChipUsed',
      };

    case ContentTypes.COLLECTION:
      return {
        chipColor: 'filterChip',
        chipColorUsed: 'filterChipUsed',
      };
  }
};

const matchAttributes = (
  a: AttributeOptionWithKey,
  b: AttributeOptionWithKey
) =>
  (a.attributeKey === b.attributeKey && a.name === b.name) ||
  a.domId === b.domId;
const removeMatchingAttrs = (
  attr: AttributeOptionWithKey,
  existingAttrs: AttributeOptionWithKey[]
) => existingAttrs.filter((a) => !matchAttributes(a, attr));
const getStartingApiAttributes = (
  startingAttributes: AttributesWithValueType | SelectedAttributes | undefined,
  attributes: ApiAttribute[] | undefined
): ApiAttribute[] => {
  if (!attributes || !startingAttributes) return [];

  const startingApiAttributes: (ApiAttribute | undefined)[] = Object.keys(
    startingAttributes
  ).map((key) => {
    // TS can't infer this, just believes it is type 'string'.
    const value = startingAttributes[key as AllowedApiAttributeKeys];
    if (!value) return undefined;
    const attribute = attributes.find((attr) => attr.key === key);
    if (!attribute) return undefined;
    const options = (attribute.options as AllowedAttributeModel[]).filter((o) =>
      value.includes(o.id)
    );
    return {
      key,
      options,
    } as ApiAttribute;
  });

  return startingApiAttributes.filter(
    (attr) => attr !== undefined
  ) as ApiAttribute[];
};
const isFuseResult = <T,>(result: any): result is Fuse.FuseResult<T> =>
  !!('item' in result && 'score' in result);
const assembleSelectedAttributes = (
  attrs: AttributeOptionWithKey[]
): SelectedAttributes => {
  const selectedAttributes: SelectedAttributes = {};

  attrs.forEach((attr) => {
    const { selectedReturnKey, id, name } = attr;
    // specifically doing a nested if here over &&. This is so the outer 'else'
    // can infer that the attribute key is NOT 'searchQuery'.
    if (selectedReturnKey === 'searchQuery') {
      if (typeof name === 'string') {
        selectedAttributes.searchQuery = name;
      }
    } else {
      if (selectedAttributes[selectedReturnKey]) {
        selectedAttributes[selectedReturnKey]?.push(id);
      } else {
        selectedAttributes[selectedReturnKey] = [id];
      }
    }
  });
  return selectedAttributes;
};
const assembleAttributeIdMatrix = (
  attributes: SearchableAttribute[],
  staticAttributes: (SearchableAttribute | undefined)[],
  keywordAttribute: KeywordAttribute | undefined,
  searchInputId: string
) => {
  let keywordAttributeId: string[] = [];
  let staticAttributeIds: string[][] = [];
  // get domId array from attributes
  const attributeIds = attributes.map((attr) =>
    attr.options
      .filter((o): o is AttributeOption & { domId: string } => !!o.domId)
      .map(({ domId }) => domId)
  );

  if (staticAttributes) {
    staticAttributeIds = staticAttributes
      .filter((attr): attr is SearchableAttribute => !!attr)
      .map((attr) => attr.options.map((option) => option.domId || ''));
  }

  if (keywordAttribute?.domId) {
    keywordAttributeId = [keywordAttribute?.domId];
  }

  // Yes it's a mess, but let me explain... This has to be in the shape of string[][]
  // some of the variables here are already that type, others just string[]
  // and some are simply string; so gets wrapped as [variable].
  // finally, filter out any empty inner arrays
  return [
    [searchInputId],
    ...staticAttributeIds,
    keywordAttributeId,
    ...attributeIds,
  ].filter((attrIds) => attrIds.length > 0);
};

const wrapArrayIndex = (index: number, arrayLength = 0) => {
  if (arrayLength <= 0) return 0;
  if (index < 0) return arrayLength - 1;

  return index % arrayLength;
};

const fuseSearch = <T extends AllowedAttributeModel>({
  searchTerm,
  keys,
  options,
}: FuseSearchArgs<T>) => {
  const fuse = new Fuse(options, {
    keys,
    includeScore: true, // note: the isFuseResult type-guard relies on score be present
  });

  if (searchTerm.length < SEARCH_QUERY_LENGTH_REQUIREMENT) {
    return { results: [], totalCount: 0 };
  }

  // a little hacky, but otherwise it'll return as Fuse.FuseResult<AllowedAttributeModel>
  // and it would be nice to be more specific here.
  const results = fuse.search(searchTerm) as Fuse.FuseResult<T>[];

  // limit actual results, but return total count of all results
  return {
    results: results.slice(0, ATTRIBUTE_OPTION_LIMIT),
    totalCount: results.length,
  };
};

const formatAttribute = <T extends AllowedAttributeModel>({
  key,
  selectedReturnKey,
  results,
  totalCount,
  optionNameProperty,
  optionNameFormatter,
}: FormatAttributeArgs<T>): SearchableAttribute | undefined => {
  if (results.length <= 0) {
    return undefined;
  }

  return {
    key,
    selectedReturnKey,
    options: results.map<AttributeOption>((result: Fuse.FuseResult<T> | T) => {
      // result will either be a fuse result or the attribute option itself.
      const option = isFuseResult<T>(result) ? result.item : result;
      const attributeOption: AttributeOption = {
        id: option.id,
        name: getOptionName<T>(option, optionNameProperty, optionNameFormatter),
        domId: `${key}-${option.id}-option-chip`,
        chipColor: 'filterChip',
        chipColorUsed: 'filterChipUsed',
      };

      return attributeOption;
    }),
    score: isFuseResult<T>(results[0]) ? results[0]?.score || 0 : 0,
    additionalOptionCount: totalCount - results.length,
  };
};

const searchAndFormatAttribute = <T extends AllowedAttributeModel>(
  fuseSearchArgs: FuseSearchArgs<T>,
  formatAttributeArgs: Omit<FormatAttributeArgs<T>, 'results' | 'totalCount'>,
  formatOnly?: boolean
) => {
  if (formatOnly) {
    const results = fuseSearchArgs.options as T[];
    return formatAttribute({
      ...formatAttributeArgs,
      results,
      totalCount: results.length,
    });
  }
  const { results, totalCount } = fuseSearch(fuseSearchArgs);
  return formatAttribute({ ...formatAttributeArgs, results, totalCount });
};

// Takes an ApiAttribute, and determines which configuration should be given
// to the search and format functions for that specific attribute.
const determineAttribute = (
  attribute: ApiAttribute,
  searchTerm = '',
  prohibitedAttributes?: AllowedAttributeKeys[],
  formatOnly?: boolean
): SearchableAttribute | undefined => {
  const { key, options } = attribute;
  if (prohibitedAttributes?.includes(key)) {
    return undefined;
  }
  switch (key) {
    case 'Device': {
      return searchAndFormatAttribute<DeviceModel>(
        { searchTerm, keys: ['trackingName', 'manufacturer'], options },
        {
          key,
          selectedReturnKey: 'deviceIds',
          optionNameFormatter: formatDeviceName,
        },
        formatOnly
      );
    }

    case 'Manufacturer': {
      return searchAndFormatAttribute<ManufacturerModel>(
        { searchTerm, keys: ['name'], options },
        { key, selectedReturnKey: 'manufacturerIds' },
        formatOnly
      );
    }

    case 'DeviceType': {
      return searchAndFormatAttribute<DeviceTypeModel>(
        { searchTerm, keys: ['name'], options },
        { key, selectedReturnKey: 'deviceTypeIds' },
        formatOnly
      );
    }

    case 'Language': {
      return searchAndFormatAttribute<LanguageModel>(
        { searchTerm, keys: ['name', 'shortCode', 'displayName'], options },
        {
          key,
          selectedReturnKey: 'languageIds',
          optionNameFormatter: formatLanguageName,
        },
        formatOnly
      );
    }

    case 'OperatingSystem': {
      return searchAndFormatAttribute<OperatingSystemModel>(
        { searchTerm, keys: ['name'], options },
        { key, selectedReturnKey: 'operatingSystemIds' },
        formatOnly
      );
    }

    case 'OperatingSystemRelease': {
      return searchAndFormatAttribute<OperatingSystemReleaseModel>(
        { searchTerm, keys: ['name', 'operatingSystemName'], options },
        {
          key,
          selectedReturnKey: 'operatingSystemReleaseIds',
          optionNameFormatter: formatOsReleaseName,
        },
        formatOnly
      );
    }

    case 'OperatingSystemVersion': {
      return searchAndFormatAttribute<OperatingSystemVersionModel>(
        {
          searchTerm,
          keys: ['name', 'operatingSystem', 'operatingSystemRelease'],
          options,
        },
        {
          key,
          selectedReturnKey: 'operatingSystemVersionIds',
          optionNameFormatter: formatOsVersionName,
        },
        formatOnly
      );
    }

    case 'Space': {
      return searchAndFormatAttribute<SpaceModel>(
        { searchTerm, keys: ['name'], options },
        { key, selectedReturnKey: 'spaceIds' },
        formatOnly
      );
    }

    case 'Topic': {
      return searchAndFormatAttribute<TopicModel>(
        { searchTerm, keys: ['slug', 'title'], options },
        {
          key,
          selectedReturnKey: 'topicIds',
          optionNameFormatter: formatTopicName,
        },
        formatOnly
      );
    }

    // Note: ContentType is a little different than the other attributes since it is used
    // as a static attribute
    case 'ContentType': {
      return {
        key: 'ContentType',
        selectedReturnKey: 'contentTypeIds',
        options: (options as ContentTypeModel[]).map<AttributeOption>(
          (contentType) => {
            const { id, name } = contentType;
            const { chipColor, chipColorUsed } = getContentTypeChipColor(name);
            return {
              id,
              name: <ContentTypeName {...{ contentType }} />,
              chipColor,
              chipColorUsed,
              domId: `content-type-${id}`,
            };
          }
        ),
        score: 0,
      };
    }
  }
};

export const extractQueryAttributes = (
  location: Location
): AttributesWithValueType => {
  const params = new URLSearchParams(location.search);

  const Language = params.getAll('Language[]').map(Number);
  const Space = params.getAll('Space[]').map(Number);
  const ContentType = params.getAll('ContentType[]').map(Number);

  // attributes
  const Topic = params.getAll('Topic[]').map(Number);
  const DeviceType = params.getAll('DeviceType[]').map(Number);
  const Manufacturer = params.getAll('Manufacturer[]').map(Number);
  const Device = params.getAll('Device[]').map(Number);
  const OperatingSystem = params.getAll('OperatingSystem[]').map(Number);
  const OperatingSystemRelease = params
    .getAll('OperatingSystemRelease[]')
    .map(Number);
  const OperatingSystemVersion = params
    .getAll('OperatingSystemVersion[]')
    .map(Number);

  // content title
  const searchQuery = params.get('ContentTitle[]') || '';
  return {
    Language,
    Space,
    ContentType,
    Topic,
    DeviceType,
    Manufacturer,
    Device,
    OperatingSystem,
    OperatingSystemRelease,
    OperatingSystemVersion,
    searchQuery,
  };
};

export const useAttributeFetch = (attributeKeys: AllowedApiAttributeKeys[]) => {
  const [attributes, setAttributes] = useState<ApiAttribute[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const stableAttributeKeys = useMemo(() => attributeKeys, [attributeKeys]);

  const api = useOzmoApiService();

  useEffect(() => {
    let mounted = true;
    const handleAttributeFetch = async () => {
      const promises = stableAttributeKeys.map(async (attr) => {
        return await api[attr].getAllAsync(undefined, undefined, {
          perPage: 500,
          allPages: true,
        });
      });

      try {
        const attributeModels: AllowedAttributeModels[] = await Promise.all(
          promises
        );
        mounted &&
          setAttributes(
            stableAttributeKeys.map((attr, index) => ({
              key: attr,
              options: attributeModels[index],
            }))
          );
      } catch (error) {
        mounted && setIsError(true);
        mounted && setAttributes([]);
        console.warn(error);
      } finally {
        mounted && setIsLoading(false);
      }
    };

    handleAttributeFetch();

    return () => {
      mounted = false;
    };
  }, [api, stableAttributeKeys]);

  return {
    attributes,
    isLoading,
    isError,
  };
};

const baseKeywordAttribute: KeywordAttribute = {
  attributeKey: 'ContentTitle',
  selectedReturnKey: 'searchQuery',
  id: 0,
  name: '',
  chipColor: 'filterChip',
  chipColorUsed: 'filterChipUsed',
  domId: 'keyword-0-filter-chip',
};

// used to get all attributes minus static attributes.
export const useAttributeSearch = (
  onSelectedAttrChange?: (attrs: SelectedAttributes) => void,
  onResultsPanelToggle?: (isOpen: boolean) => void,
  startingAttributes?: AttributesWithValueType | SelectedAttributes,
  prohibitedAttributes?: AllowedAttributeKeys[],
  containerRef?: React.RefObject<HTMLDivElement>,
  syncWithQueryParams: boolean = false
) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedAttributes, setSelectedAttributes] = useState<
    AttributeOptionWithKey[]
  >([]);
  const [resultsPanelOpen, setResultsPanelOpen] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [keywordAttribute, setKeywordAttribute] = useState<
    KeywordAttribute | undefined
  >();
  const [keywordSelected, setKeywordSelected] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [startingAttributesSettled, setStartingAttributesSettled] = useState(
    false
  );
  const [stableStartingAttributes, setStableStartingAttributes] = useState(
    startingAttributes
  );
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const attributeKeys = useMemo(
    (): AllowedApiAttributeKeys[] => [
      'Device',
      'Manufacturer',
      'DeviceType',
      'Language',
      'Space',
      'OperatingSystem',
      'OperatingSystemRelease',
      'OperatingSystemVersion',
      'Topic',
    ],
    []
  );
  const staticAttributeKeys = useMemo(
    (): AllowedApiAttributeKeys[] => ['ContentType'],
    []
  );
  const { attributes, isLoading } = useAttributeFetch(attributeKeys);
  // this is called separately since it will help feed static filters; whereas
  // "attributes" above will feed into regular attributes results
  const { attributes: staticAttributes } = useAttributeFetch(
    staticAttributeKeys
  );

  const formattedAttributes = useMemo(
    () =>
      attributes
        .map((attr) =>
          determineAttribute(attr, debouncedSearchTerm, prohibitedAttributes)
        )
        .filter((attr): attr is SearchableAttribute => !!attr)
        .sort((a, b) => a.score - b.score),
    [debouncedSearchTerm, prohibitedAttributes, attributes]
  );

  useEffect(() => {
    if (!isEqual(startingAttributes, stableStartingAttributes)) {
      setStableStartingAttributes(startingAttributes);
      setStartingAttributesSettled(false);
    }
  }, [startingAttributes, stableStartingAttributes]);

  useEffect(() => {
    onResultsPanelToggle?.(resultsPanelOpen);
  }, [resultsPanelOpen, onResultsPanelToggle]);

  // checks if a click has been made outside the attribute selector
  useEffect(() => {
    const attributeSelectorContainer = containerRef?.current
      ? containerRef.current
      : document.getElementById('attribute-selector-container');

    const handleOutsideContainerClick = (e: MouseEvent) => {
      if (
        isNode(e?.target) &&
        !attributeSelectorContainer?.contains(e.target)
      ) {
        setResultsPanelOpen(false);
      }
    };

    window.addEventListener('click', handleOutsideContainerClick);
    const unsubscribe = () =>
      window.removeEventListener('click', handleOutsideContainerClick);

    return unsubscribe;
  }, [containerRef]);

  // pre-populates the selectedAttributes with startingAttributes
  useEffect(() => {
    if (startingAttributesSettled) return;

    const startingApiAttributes = getStartingApiAttributes(
      stableStartingAttributes,
      [...attributes, ...staticAttributes]
    );
    const formattedStartingAttributes = startingApiAttributes
      .map((attr) => determineAttribute(attr, undefined, undefined, true))
      .filter((attr): attr is SearchableAttribute => !!attr);
    const startingAttrOptionsWithKey: AttributeOptionWithKey[] = [];
    if (formattedStartingAttributes) {
      formattedStartingAttributes.forEach((attr) => {
        return attr.options.forEach((o) => {
          startingAttrOptionsWithKey.push({
            ...o,
            attributeKey: attr.key,
            selectedReturnKey: attr.selectedReturnKey,
          });
        });
      });
    }

    setSelectedAttributes(startingAttrOptionsWithKey);

    if (stableStartingAttributes?.searchQuery) {
      const startingKeywordAttribute: KeywordAttribute = {
        ...baseKeywordAttribute,
        name: stableStartingAttributes.searchQuery,
      };

      setKeywordAttribute(startingKeywordAttribute);

      setKeywordSelected(true);

      setSelectedAttributes((currentAttrs) => [
        ...currentAttrs,
        {
          ...startingKeywordAttribute,
          domId: `${startingKeywordAttribute.domId}-selected`,
        },
      ]);
    }

    // Only start reporting changes after the starting attributes have been set; don't report empty attributes
    const startingAttributeCount = Object.entries(
      stableStartingAttributes ?? {}
    )
      .reduce((vals, [_, value]) => [...vals, value], [] as any[])
      .flat()
      .filter(Boolean).length;

    const parsedAttributeCount =
      startingAttrOptionsWithKey.length +
      (stableStartingAttributes?.searchQuery ? 1 : 0);

    if (startingAttributeCount === parsedAttributeCount) {
      setStartingAttributesSettled(true);
    }

    // wants startingAttributes included, but this would create a max call error
    // and ideally that prop should not change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setStartingAttributesSettled,
    startingAttributesSettled,
    attributes,
    staticAttributes,
    stableStartingAttributes,
  ]);

  // triggered as selectedAttributes change. Calls the passed on onSelectedAttrChange
  // and checks if the KeywordAttribute (Content Title chip) has been selected
  useEffect(() => {
    if (!startingAttributesSettled) return;
    onSelectedAttrChange &&
      onSelectedAttrChange(assembleSelectedAttributes(selectedAttributes));

    // detect if a Content Title (keyword) chip  has been applied
    setKeywordSelected(
      selectedAttributes.some((attr) => attr.attributeKey === 'ContentTitle')
    );
  }, [selectedAttributes, startingAttributesSettled, onSelectedAttrChange]);

  // controls the KeywordAttribute. Updating it with the searchTerm text or
  // setting it to undefined if the searchTerm.length isn't long enough or
  // the keyword has already been selected
  useEffect(() => {
    if (
      debouncedSearchTerm.length >= SEARCH_QUERY_LENGTH_REQUIREMENT &&
      !keywordSelected &&
      !prohibitedAttributes?.includes('ContentTitle')
    ) {
      setKeywordAttribute({
        ...baseKeywordAttribute,
        name: debouncedSearchTerm,
      });
    } else {
      setKeywordAttribute(undefined);
    }
  }, [debouncedSearchTerm, keywordSelected, prohibitedAttributes]);

  // controls when to show actual attribute results
  useEffect(() => {
    setShowResults(
      debouncedSearchTerm.length >= SEARCH_QUERY_LENGTH_REQUIREMENT
    );
  }, [debouncedSearchTerm]);

  const handleSearchInput: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => setSearchTerm(e.target.value),
    []
  );
  const handleShowResults: FocusEventHandler<HTMLInputElement> = useCallback(() => {
    setResultsPanelOpen(true);
  }, []);

  const handleHideResults = useCallback(() => {
    setResultsPanelOpen(false);
  }, []);

  const handleAttributeSelect = useCallback(
    (attr: AttributeOptionWithKey) => {
      if (syncWithQueryParams) {
        if (attr.attributeKey === 'ContentTitle') {
          searchParams.append(`${attr.attributeKey}[]`, `${attr.name}`);
        } else {
          searchParams.append(`${attr.attributeKey}[]`, `${attr.id}`);
        }
        setSearchParams(searchParams, { replace: true });
      }

      setSelectedAttributes((currentAttrs) => [
        ...currentAttrs,
        { ...attr, domId: `${attr.domId}-selected` },
      ]);
    },
    [syncWithQueryParams, searchParams, setSearchParams]
  );

  const handleAttributeRemove = useCallback(
    (attr: AttributeOptionWithKey) => {
      if (syncWithQueryParams) {
        const targetKey = `${attr.attributeKey}[]`;

        const matchingValues = searchParams.getAll(targetKey);
        const valuesToPreserve = matchingValues.filter((v) => {
          if (attr.attributeKey === 'ContentTitle') {
            return v !== attr.name;
          }
          return v !== String(attr.id);
        });

        // Deletes *all* instances of `targetKey`, which is why we need `valuesToPreserve`
        // to restore key-value pairs that the user didn't delete.
        searchParams.delete(targetKey);
        valuesToPreserve.forEach((v) => searchParams.append(targetKey, v));

        setSearchParams(searchParams, { replace: true });
      }

      setSelectedAttributes((currentAttrs) =>
        removeMatchingAttrs(attr, currentAttrs)
      );
    },
    [syncWithQueryParams, searchParams, setSearchParams]
  );

  const isAttributeSelected = useCallback(
    (attr: AttributeOptionWithKey) =>
      selectedAttributes.some((a) => matchAttributes(a, attr)),
    [selectedAttributes]
  );

  const clearSelectedAttributes = useCallback(() => {
    setSelectedAttributes([]);
    onSelectedAttrChange?.({});
  }, [onSelectedAttrChange]);

  return {
    searchTerm,
    isLoading,
    attributes: formattedAttributes,
    keywordAttribute,
    resultsPanelOpen,
    showResults,
    selectedAttributes,
    handleSearchInput,
    handleShowResults,
    handleHideResults,
    handleAttributeSelect,
    handleAttributeRemove,
    isAttributeSelected,
    clearSelectedAttributes,
  };
};

export const useStaticAttributes = (
  prohibitedTypes?: ContentEntryContentType[],
  prohibitedAttributes?: AllowedAttributeKeys[]
) => {
  const [staticAttributes, setStaticAttributes] = useState<
    SearchableAttribute[]
  >([]);
  const [allowedAttributes, setAllowAttributes] = useState<ApiAttribute[]>([]);
  const staticAttributeKeys = useMemo(
    (): AllowedApiAttributeKeys[] => ['ContentType'],
    []
  );
  const { attributes, isLoading } = useAttributeFetch(staticAttributeKeys);

  const addStaticAttribute = useCallback(
    (attr: SearchableAttribute | undefined) => {
      if (attr) {
        setStaticAttributes((currentAttrs) => {
          if (
            attr &&
            !currentAttrs.some((currentAttr) => currentAttr.key === attr.key)
          ) {
            return [...currentAttrs, attr];
          }
          return currentAttrs;
        });
      }
    },
    []
  );

  // filter out any prohibited attributes
  useEffect(() => {
    if (!isLoading) {
      setAllowAttributes(
        attributes.filter((attr) => !prohibitedAttributes?.includes(attr.key))
      );
    }
  }, [isLoading, attributes, prohibitedAttributes]);

  // set ContentType attribute
  useEffect(() => {
    if (!isLoading) {
      const contentTypeAttribute = allowedAttributes.find(
        (attr) => attr.key === 'ContentType'
      );
      if (contentTypeAttribute) {
        contentTypeAttribute.options = (contentTypeAttribute.options as ContentTypeModel[]).filter(
          (o) => !prohibitedTypes?.includes(o.name)
        );
        const searchableContentTypeAttr = determineAttribute(
          contentTypeAttribute
        );

        addStaticAttribute(searchableContentTypeAttr);
      }
    }
  }, [isLoading, allowedAttributes, prohibitedTypes, addStaticAttribute]);

  return { staticAttributes };
};

export const useKeyboardNavigation = (
  attributes: SearchableAttribute[],
  staticAttributes: (SearchableAttribute | undefined)[],
  keywordAttribute: KeywordAttribute | undefined,
  containerRef: React.RefObject<HTMLDivElement>,
  onEscPressed?: VoidFunction,
  onShiftEnterPressed?: VoidFunction
) => {
  const rootElement = containerRef.current ? containerRef.current : document;
  const searchInputId = useMemo(() => 'attribute-search-input', []);
  const [attributeIdx, setAttributeIdx] = useState(0);
  const [, setOptionIdx] = useState(0);

  // A multidimensional array that contains the element IDs (string[][]) for rendered attribute chips.
  // The idea being that the first level of the array are the attribute types (device, manufacture),
  // and the second level of the array are the options (iphone 12, Apple).
  //
  // Since the search input, keyword attribute, and static attributes must also fit within this
  // keyboard navigation flow, they are shimmed in here using the same array shape.

  const attributeMatrix = useMemo(
    () =>
      assembleAttributeIdMatrix(
        attributes,
        staticAttributes,
        keywordAttribute,
        searchInputId
      ),
    [attributes, staticAttributes, keywordAttribute, searchInputId]
  );

  const applyFocusToAttribute = useCallback(
    (attributeIdx: number, optionIdx: number) => {
      const attrId = attributeMatrix[attributeIdx]?.[optionIdx];
      (rootElement.querySelector(`#${attrId}`) as HTMLElement | null)?.focus();
    },
    [attributeMatrix, rootElement]
  );

  const changeAttributeIdx = useCallback(
    (delta: number) => {
      if (attributeMatrix) {
        setAttributeIdx((currentIdx) => {
          const newIdx =
            wrapArrayIndex(currentIdx + delta, attributeMatrix.length) || 0;
          applyFocusToAttribute(newIdx, 0);
          return newIdx;
        });
        setOptionIdx(0);
      }
    },
    [attributeMatrix, applyFocusToAttribute]
  );

  const changeOptionIdx = useCallback(
    (delta: number) => {
      if (attributeMatrix) {
        setOptionIdx((currentIdx) => {
          const newIdx =
            wrapArrayIndex(
              currentIdx + delta,
              attributeMatrix[attributeIdx]?.length
            ) || 0;
          applyFocusToAttribute(attributeIdx, newIdx);
          return newIdx;
        });
      }
    },
    [attributeMatrix, attributeIdx, applyFocusToAttribute]
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowDown':
          changeAttributeIdx(1);
          e.preventDefault(); // prevent screen and result panel scrolling
          break;
        case 'ArrowUp':
          changeAttributeIdx(-1);
          e.preventDefault(); // prevent screen and result panel scrolling
          break;
        case 'ArrowLeft':
          changeOptionIdx(-1);
          break;
        case 'ArrowRight':
          changeOptionIdx(1);
          break;
        case 'Enter':
          if (e.shiftKey && onShiftEnterPressed) {
            onShiftEnterPressed();
            // prevent adding an attribute if Shift+Enter was clicked while one was focused
            e.preventDefault();
          }
          break;
        case 'Escape':
          onEscPressed?.();
          // Swallow this event so that if we're inside a modal it does not close
          e.stopPropagation();
          break;
      }
    },
    [changeAttributeIdx, changeOptionIdx, onShiftEnterPressed, onEscPressed]
  );

  // Resets the indices when the input element gets focus.
  // This is mostly to account for the event in which a user is navigating with the
  // keyboard, but uses the mouse to change their input text. Without this, if the
  // user was to use keyboard commands again, it would place focus where they
  // originally were in the attributes. This is disorientating since the attributes
  // change as the input text changes.
  useEffect(() => {
    const onInputFocus = () => {
      setAttributeIdx(0);
      setOptionIdx(0);
    };
    const inputElement = document.getElementById(searchInputId);

    if (inputElement) {
      inputElement.addEventListener('focus', onInputFocus);
    }

    const unsubscribe = () => {
      if (inputElement) {
        inputElement.removeEventListener('focus', onInputFocus);
      }
    };

    return unsubscribe;
  }, [searchInputId]);

  return { handleKeyDown };
};

export const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebounceValue] = useState(value);
  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setDebounceValue(value);
    }, delay);
    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const useHigherOrderAttributes = (
  onSelectedAttrChange: Parameters<typeof useAttributeSearch>[0],
  startingAttributes: Parameters<typeof useAttributeSearch>[2]
): [
  Parameters<typeof useAttributeSearch>[0],
  Parameters<typeof useAttributeSearch>[2]
] => {
  const api = useOzmoApiService();
  const [stableAttributes, setStableAttributes] = useState(startingAttributes);

  useEffect(() => {
    if (!stableAttributes) {
      setStableAttributes(startingAttributes);
    }
  }, [stableAttributes, startingAttributes]);

  const onSelectedChange = useCallback(
    (attrs: SelectedAttributes) => {
      onSelectedAttrChange?.(attrs);

      api.ContentEntry.getHigherOrderAttributes(attrs).then((attributes) => {
        const newAttrs = merge(attrs, attributes);
        setStableAttributes(formatStartingAttributes(newAttrs));
        onSelectedAttrChange?.(newAttrs);
      });
    },
    [onSelectedAttrChange, api.ContentEntry]
  );

  return [onSelectedChange, stableAttributes];
};
