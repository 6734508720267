import { FC } from 'react';
import {
  MenuBook,
  FormatListNumbered,
  LocationOn,
  DynamicFeed,
  EditOff,
  Delete,
  Article,
} from '@mui/icons-material';
import { ContentTypes } from 'types/enums';

import ContentTypeBase, { StyleOverrides } from './content-type-base';

type Props = {
  className?: string;
  contentType: ContentEntryContentType;
  label?: string;
  styleOverrides?: StyleOverrides;
};

const ContentTypeWithIcon: FC<Props> = ({
  contentType,
  label,
  styleOverrides,
  className,
}) => {
  if (!contentType) return null;
  switch (contentType) {
    case ContentTypes.TUTORIAL:
      return (
        <ContentTypeBase
          {...{ className, label }}
          color="var(--color-content-tutorial)"
          Icon={FormatListNumbered}
          {...styleOverrides}
        />
      );
    case ContentTypes.TOUR:
      return (
        <ContentTypeBase
          {...{ className, label }}
          color="var(--color-content-tutorial)"
          Icon={LocationOn}
          {...styleOverrides}
        />
      );
    case ContentTypes.TOUR_DEPRECATED:
      return (
        <ContentTypeBase
          {...{ className, label: `${label} (Deprecated)` }}
          // tour and tutorial use the same color
          color="var(--color-content-tutorial)"
          Icon={LocationOn}
          {...styleOverrides}
        />
      );
    case ContentTypes.COLLECTION:
      return (
        <ContentTypeBase
          {...{ className, label }}
          color="var(--color-content-collection)"
          Icon={MenuBook}
          {...styleOverrides}
        />
      );
    case 'topicGroup':
      return (
        <ContentTypeBase
          {...{ className, label }}
          color="var(--color-content-topic-group)"
          Icon={DynamicFeed}
          {...styleOverrides}
        />
      );
    case ContentTypes.LEGACY_TUTORIAL:
    case ContentTypes.LEGACY_TOUR:
      return (
        <ContentTypeBase
          {...{ className, label }}
          color="var(--color-neutral-six)"
          Icon={EditOff}
          {...styleOverrides}
        />
      );
    case ContentTypes.DELETED:
      return (
        <ContentTypeBase
          className={className}
          label="In Trash"
          color="var(--color-neutral-six)"
          Icon={Delete}
          {...styleOverrides}
          fontWeight="normal"
          fontStyle="italic"
        />
      );
    default:
      return (
        <ContentTypeBase
          className={className}
          label={label || contentType || 'Unknown content type'}
          color="var(--color-content-tutorial)"
          Icon={Article}
          {...styleOverrides}
        />
      );
  }
};

export default ContentTypeWithIcon;
