import { useCallback, useState } from 'react';
import { useAppToast } from 'contexts/app-toast-context';
import useOzmoApiService from 'contexts/ozmo-api-service-context';

export enum Status {
  START,
  IN_PROGRESS,
  COMPLETE,
  ERROR,
}

export const useTranslateContentEntry = (contentEntryId: number) => {
  const api = useOzmoApiService();
  const { isLoading, data: contentEntry } = api.ContentEntry.get({
    id: contentEntryId,
  });
  const dispatchToast = useAppToast();
  const [targets, setTargets] = useState<string[]>([]);
  const [state, setState] = useState<Status>(Status.START);

  const source = contentEntry?.localizedContentEntries?.find(
    (lce) => lce.languageShortCode === 'en'
  );

  const { title } = source?.properties || {};
  const slug = contentEntry?.topic?.slug;

  const onRefuse = useCallback(() => {
    // reset after the fade out so it doesn't snap back to default state while still visible
    setTimeout(() => {
      setState(Status.START);
      setTargets([]);
    }, 500);
  }, []);

  const handleSelectTarget = (languageIds: string[]) => {
    setTargets(languageIds);
  };

  const languages = contentEntry?.localizedContentEntries
    ?.filter((lce) => lce.id !== source?.id)
    ?.map(({ languageShortCode, languageDisplayName }) => ({
      value: languageShortCode,
      chipLabel: languageShortCode,
      label: `${languageDisplayName} (${languageShortCode})`,
    }));

  const onSubmit = async () => {
    if (!source) {
      onRefuse();
      dispatchToast({
        level: 'error',
        message: 'An English version of this content does not exist.',
      });
      return;
    }

    try {
      setState(Status.IN_PROGRESS);

      await Promise.all(
        targets.map((target) =>
          api.LocalizedContentEntry.translateAsync({
            id: source.id,
            contentEntryId: contentEntryId,
            targetLanguageShortCode: target,
          })
        )
      );

      setState(Status.COMPLETE);
      return true;
    } catch {
      setState(Status.ERROR);
    } finally {
      setTargets([]);
    }
  };

  return {
    handleSelectTarget,
    onSubmit,
    onRefuse,
    languages,
    targets,
    source,
    isLoading,
    title,
    slug,
    state,
  };
};
