import { Grid, Skeleton, styled } from '@mui/material';
import { ReactElement, ReactNode, FC } from 'react';
import { TabLabels, TabLabelProps } from 'scenes/tabbed-content';
import AttributesTagCloud from 'components/tag-cloud/attributes-tag-cloud';
import usePreviousPage from 'services/utils/use-previous-page';

import MoreActionsMenu from './more-actions-menu';

const StyledHeaderContainer = styled('header')`
  position: sticky;
  top: 48px;
  z-index: 10;
  max-width: 100vw;
  background-color: var(--color-neutral-three);
  padding: 1rem 2rem 0 2rem;
  margin: -2rem -2rem 0 -2rem;
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0.1),
    0 3px 2px -2px rgba(0, 0, 0, 0.2);
`;

// Specially styled grid to fade out long attribute lists.
const StyledAttributeGrid = styled(Grid)`
  white-space: nowrap;
  overflow: hidden;
  position: relative;

  &:after {
    position: absolute;
    content: '';
    inset: 0 0 0 auto;
    width: 3rem;

    background: linear-gradient(
      to right,
      transparent,
      var(--color-neutral-three)
    );
  }
`;

type Props = Partial<TabLabelProps> & {
  isLoading: boolean;
  isError: boolean;
  error: any;
  autoSaveIndicator?: ReactNode;
  title: ReactNode;
  actions?: ReactElement[];
  actionButtons?: ReactElement[];
  subtitle?: ReactNode;
  manufacturers?: ManufacturerModel[];
  devices?: DeviceModel[];
  deviceTypes?: DeviceTypeModel[];
  operatingSystems?: OperatingSystemModel[];
  operatingSystemReleases?: OperatingSystemReleaseModel[];
  operatingSystemVersions?: OperatingSystemVersionModel[];
  languageSelector: ReactNode;
};

const StickyHeader: FC<Props> = ({
  isLoading,
  isError,
  error,
  autoSaveIndicator,
  title,
  actions,
  actionButtons,
  subtitle,
  manufacturers = [],
  devices = [],
  deviceTypes = [],
  operatingSystems = [],
  operatingSystemReleases = [],
  operatingSystemVersions = [],
  tabLabels,
  languageSelector,
  currentTab,
  setCurrentTab,
}) => {
  const { previousPageButton } = usePreviousPage();

  if (isLoading) {
    return (
      <Skeleton
        variant="rectangular"
        height="110px"
        animation="wave"
        data-testid="sticky-header-loader"
      />
    );
  }

  if (isError) return <>{`Error: ${JSON.stringify(error)}`}</>;

  return (
    <StyledHeaderContainer data-testid="sticky-header:container">
      <Grid container justifyContent="space-between">
        <Grid item>{previousPageButton}</Grid>
        {autoSaveIndicator && <Grid item>{autoSaveIndicator}</Grid>}
      </Grid>
      <Grid container justifyContent="space-between" wrap="wrap-reverse">
        <Grid item>{title}</Grid>
        <Grid item>
          <Grid container spacing={1}>
            {actionButtons?.map((button) => (
              <Grid key={button.key} item>
                {button}
              </Grid>
            ))}
            <MoreActionsMenu actions={actions} />
          </Grid>
        </Grid>
      </Grid>
      {subtitle && (
        <Grid container marginBottom={1.5}>
          {subtitle}
        </Grid>
      )}
      <Grid container>
        <StyledAttributeGrid item xs={6} lg={4}>
          <AttributesTagCloud
            manufacturers={manufacturers}
            devices={devices}
            deviceTypes={deviceTypes}
            operatingSystems={operatingSystems}
            operatingSystemReleases={operatingSystemReleases}
            operatingSystemVersions={operatingSystemVersions}
          />
        </StyledAttributeGrid>
      </Grid>
      <Grid container justifyContent="space-between">
        <Grid item>
          {tabLabels && currentTab !== undefined && setCurrentTab && (
            <TabLabels
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              tabLabels={tabLabels}
            />
          )}
        </Grid>
        <Grid item>{languageSelector}</Grid>
      </Grid>
    </StyledHeaderContainer>
  );
};

export default StickyHeader;
