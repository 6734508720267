import { FC } from 'react';
import { Stack, Typography } from '@mui/material';

const Error: FC = () => (
  <Stack spacing={2} alignItems="center" data-testid="translate-error">
    <svg
      width="250"
      height="250"
      viewBox="0 0 250 250"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M178.552 51.8813C178.789 51.7469 178.973 51.5355 179.073 51.2821C179.173 51.0287 179.183 50.7487 179.102 50.4886C179.021 50.2284 178.854 50.0039 178.627 49.8522C178.401 49.7006 178.13 49.631 177.858 49.655L160.267 53.9551C160.039 53.9768 159.823 54.0631 159.643 54.2039C159.463 54.3448 159.327 54.5342 159.252 54.7498C159.176 54.9654 159.164 55.1981 159.216 55.4205C159.269 55.6428 159.384 55.8454 159.548 56.0043L164.249 60.5282C164.384 60.6573 164.487 60.8161 164.549 60.9921C164.611 61.1681 164.631 61.3561 164.607 61.5412C164.583 61.7263 164.516 61.9031 164.411 62.0575C164.306 62.2118 164.167 62.3394 164.003 62.4298L155.546 67.1186C155.291 67.2551 155.094 67.4797 154.992 67.7507C154.89 68.0216 154.89 68.3204 154.992 68.5913C155.094 68.8622 155.291 69.0868 155.546 69.2232C155.802 69.3596 156.098 69.3984 156.38 69.3326L176.453 64.9046C176.665 64.8589 176.86 64.7564 177.017 64.6083C177.175 64.4602 177.289 64.272 177.348 64.0639C177.406 63.8558 177.407 63.6356 177.35 63.4271C177.293 63.2185 177.181 63.0294 177.024 62.88L171.531 57.5959C171.397 57.4666 171.295 57.3079 171.234 57.1322C171.172 56.9566 171.153 56.7691 171.177 56.5845C171.201 56.4 171.267 56.2236 171.372 56.0695C171.476 55.9153 171.615 55.7877 171.777 55.6968L178.552 51.8813Z"
        stroke="#B51523"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.4483 51.8813C71.2113 51.7469 71.0275 51.5355 70.9273 51.2821C70.8271 51.0287 70.8167 50.7487 70.8978 50.4886C70.9788 50.2284 71.1465 50.0039 71.3728 49.8522C71.5992 49.7006 71.8706 49.631 72.142 49.655L89.7335 53.9551C89.9609 53.9768 90.1774 54.0631 90.3573 54.2039C90.5372 54.3448 90.6729 54.5342 90.7485 54.7498C90.8241 54.9654 90.8363 55.1981 90.7837 55.4205C90.7312 55.6428 90.616 55.8454 90.4518 56.0043L85.7508 60.5282C85.616 60.6573 85.5134 60.8161 85.4512 60.9921C85.3891 61.1681 85.3692 61.3561 85.3932 61.5412C85.4171 61.7263 85.4842 61.9031 85.5891 62.0575C85.6939 62.2118 85.8336 62.3394 85.9968 62.4298L94.4542 67.1186C94.7095 67.2551 94.9065 67.4797 95.0085 67.7507C95.1104 68.0216 95.1103 68.3204 95.0083 68.5913C94.9062 68.8622 94.7092 69.0868 94.4538 69.2232C94.1984 69.3596 93.9022 69.3984 93.6203 69.3326L73.5467 64.9046C73.3354 64.8589 73.1405 64.7564 72.983 64.6083C72.8254 64.4602 72.7112 64.272 72.6525 64.0639C72.5939 63.8558 72.593 63.6356 72.65 63.4271C72.7069 63.2185 72.8196 63.0294 72.976 62.88L78.4692 57.5959C78.603 57.4666 78.7047 57.3079 78.7662 57.1322C78.8278 56.9566 78.8474 56.7691 78.8235 56.5845C78.7995 56.4 78.7328 56.2236 78.6286 56.0695C78.5244 55.9153 78.3855 55.7877 78.2232 55.6968L71.4483 51.8813Z"
        stroke="#B51523"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M135.846 17.744C136.025 17.3806 136.109 16.9777 136.089 16.5731C136.07 16.1684 135.947 15.7755 135.734 15.4311C135.521 15.0867 135.223 14.8022 134.87 14.6043C134.516 14.4065 134.118 14.3018 133.713 14.3H125.246C124.815 14.3 124.392 14.4167 124.023 14.6376C123.653 14.8585 123.35 15.1754 123.145 15.5546L113.689 33.1141C113.494 33.4777 113.396 33.8857 113.406 34.2982C113.415 34.7107 113.532 35.1137 113.743 35.468C113.955 35.8223 114.254 36.1157 114.613 36.3197C114.972 36.5236 115.377 36.6312 115.79 36.6319H125.539L121.714 53.2074C121.653 53.4753 121.686 53.7559 121.808 54.002C121.93 54.2481 122.133 54.4445 122.383 54.5581C122.634 54.6717 122.915 54.6956 123.181 54.6256C123.446 54.5557 123.68 54.3962 123.841 54.1742L140.006 31.8251C140.263 31.4689 140.417 31.0485 140.451 30.6103C140.485 30.1722 140.397 29.7332 140.197 29.3419C139.997 28.9506 139.692 28.6221 139.318 28.3927C138.943 28.1632 138.512 28.0417 138.073 28.0416H130.702L135.846 17.744Z"
        stroke="#B51523"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M118.764 165.861H131.585V178.006H118.764V165.861Z"
        stroke="#B51523"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.5317 178.006H150.815V226.084H99.5317V178.006Z"
        stroke="#B51523"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M137.996 200.443C137.993 202.141 137.316 203.768 136.114 204.968C134.912 206.167 133.283 206.84 131.585 206.839H118.764C117.066 206.84 115.437 206.167 114.235 204.968C113.033 203.768 112.356 202.141 112.353 200.443V178.006H137.996V200.443Z"
        stroke="#B51523"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M127.359 78.7815L129.179 95.3422H121.167L122.99 78.7815"
        stroke="#B51523"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M117.162 71.0693C117.162 73.1943 118.006 75.2323 119.509 76.7348C121.012 78.2374 123.05 79.0816 125.175 79.0816C127.3 79.0816 129.337 78.2374 130.84 76.7348C132.343 75.2323 133.187 73.1943 133.187 71.0693C133.187 68.9444 132.343 66.9064 130.84 65.4039C129.337 63.9013 127.3 63.0571 125.175 63.0571C123.05 63.0571 121.012 63.9013 119.509 65.4039C118.006 66.9064 117.162 68.9444 117.162 71.0693Z"
        stroke="#B51523"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.5316 200.443H86.7101C84.1598 200.443 81.7139 199.43 79.9105 197.627C78.1071 195.823 77.094 193.377 77.094 190.827V187.629C77.094 185.079 78.1071 182.633 79.9105 180.829C81.7139 179.026 84.1598 178.013 86.7101 178.013H93.1209C94.8211 178.013 96.4517 178.688 97.654 179.891C98.8562 181.093 99.5316 182.723 99.5316 184.424V200.443Z"
        stroke="#B51523"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.2993 95.3447H170.047V165.861H80.2993V95.3447Z"
        stroke="#B51523"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M93.4431 200.443L93.5243 224.259"
        stroke="#B51523"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.7028 224.475L80.6143 198.264"
        stroke="#B51523"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150.815 200.443H163.637C166.187 200.443 168.633 199.43 170.436 197.627C172.24 195.823 173.253 193.377 173.253 190.827V187.629C173.253 185.079 172.24 182.633 170.436 180.829C168.633 179.026 166.187 178.013 163.637 178.013H157.226C155.526 178.013 153.895 178.688 152.693 179.891C151.491 181.093 150.815 182.723 150.815 184.424V200.443Z"
        stroke="#B51523"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M156.906 200.443L156.825 224.259"
        stroke="#B51523"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M169.644 224.475L169.733 198.264"
        stroke="#B51523"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M95.8761 235.7C96.5839 234.237 96.9087 232.619 96.8199 230.996C96.7312 229.374 96.2318 227.801 95.3686 226.424C94.5055 225.047 93.3068 223.913 91.8851 223.126C90.4634 222.339 88.8651 221.927 87.2402 221.927C85.6154 221.927 84.0171 222.339 82.5954 223.126C81.1736 223.913 79.975 225.047 79.1118 226.424C78.2487 227.801 77.7493 229.374 77.6606 230.996C77.5718 232.619 77.8966 234.237 78.6044 235.7"
        stroke="#B51523"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M134.791 235.7V226.084H115.559V235.7"
        stroke="#B51523"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M171.74 235.7C172.448 234.237 172.774 232.619 172.685 230.996C172.597 229.373 172.098 227.8 171.235 226.423C170.372 225.046 169.173 223.911 167.751 223.124C166.329 222.337 164.731 221.924 163.105 221.924C161.48 221.924 159.882 222.337 158.46 223.124C157.038 223.911 155.839 225.046 154.976 226.423C154.113 227.8 153.614 229.373 153.526 230.996C153.437 232.619 153.763 234.237 154.471 235.7"
        stroke="#B51523"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M147.756 128C147.222 128.001 146.692 127.897 146.199 127.693C145.705 127.488 145.257 127.188 144.88 126.81L141.41 123.342L137.943 126.81C137.18 127.572 136.146 128 135.067 128C133.988 128 132.954 127.572 132.191 126.81C131.43 126.045 131.004 125.011 131.004 123.933C131.004 122.856 131.43 121.822 132.191 121.057L135.662 117.594L132.193 114.126C131.623 113.558 131.236 112.833 131.078 112.044C130.921 111.256 131.002 110.438 131.31 109.695C131.618 108.952 132.139 108.317 132.809 107.871C133.478 107.425 134.264 107.187 135.069 107.188C135.603 107.186 136.133 107.29 136.626 107.495C137.12 107.699 137.568 107.999 137.945 108.378L141.412 111.841L144.882 108.378C145.249 107.961 145.698 107.624 146.201 107.387C146.704 107.151 147.249 107.02 147.805 107.002C148.36 106.984 148.913 107.081 149.43 107.285C149.947 107.49 150.416 107.798 150.809 108.191C151.202 108.583 151.51 109.052 151.715 109.569C151.919 110.085 152.016 110.638 151.998 111.193C151.98 111.749 151.849 112.294 151.613 112.797C151.376 113.299 151.039 113.748 150.622 114.115L147.154 117.583L150.622 121.047C151.19 121.615 151.577 122.34 151.734 123.128C151.891 123.917 151.811 124.734 151.503 125.477C151.196 126.22 150.675 126.855 150.006 127.302C149.338 127.749 148.552 127.988 147.748 127.989L147.756 128Z"
        stroke="#B51523"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M113.916 127.893C113.379 127.895 112.848 127.79 112.353 127.585C111.857 127.38 111.408 127.078 111.029 126.698L107.55 123.215L104.069 126.698C103.695 127.1 103.244 127.423 102.743 127.646C102.242 127.87 101.7 127.99 101.152 127.999C100.603 128.009 100.058 127.908 99.5496 127.702C99.0409 127.496 98.579 127.189 98.1915 126.8C97.804 126.412 97.4988 125.949 97.2942 125.439C97.0896 124.93 96.9899 124.384 97.0008 123.835C97.0118 123.287 97.1333 122.745 97.358 122.245C97.5828 121.744 97.9062 121.293 98.3089 120.921L101.788 117.442L98.3089 113.958C97.5974 113.182 97.2131 112.161 97.2364 111.108C97.2597 110.055 97.6888 109.052 98.4339 108.308C99.179 107.564 100.183 107.137 101.235 107.116C102.288 107.095 103.307 107.482 104.081 108.196L107.561 111.675L111.04 108.196C111.806 107.43 112.844 107 113.926 107C115.009 107 116.047 107.43 116.813 108.196C117.572 108.962 117.999 109.998 117.999 111.077C117.999 112.156 117.572 113.192 116.813 113.958L113.321 117.442L116.802 120.921C117.374 121.491 117.763 122.219 117.921 123.012C118.079 123.804 117.999 124.626 117.689 125.373C117.38 126.119 116.857 126.757 116.185 127.206C115.513 127.655 114.723 127.894 113.916 127.893Z"
        stroke="#B51523"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M112.996 155C112.54 155.001 112.09 154.896 111.68 154.694C111.271 154.492 110.912 154.199 110.633 153.836C110.351 153.475 110.156 153.054 110.063 152.604C109.969 152.155 109.98 151.69 110.094 151.246C110.92 148.027 112.783 145.176 115.391 143.14C117.998 141.104 121.202 140 124.5 140C127.798 140 131.002 141.104 133.609 143.14C136.217 145.176 138.08 148.027 138.906 151.246C139.02 151.69 139.031 152.155 138.937 152.604C138.844 153.054 138.649 153.475 138.367 153.836C138.088 154.199 137.729 154.492 137.32 154.694C136.91 154.896 136.46 155.001 136.004 155C132.218 154.183 128.371 153.681 124.503 153.499C120.633 153.681 116.784 154.183 112.996 155Z"
        stroke="#B51523"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
    <Typography variant="h4" component="p">
      {'We’re sorry! Your translations could not be completed. '}
    </Typography>
  </Stack>
);

export default Error;
