import { FC } from 'react';
import { Button, MenuItem } from '@mui/material';
import Delete from '@mui/icons-material/Delete';

import { useDeleteAnswerHook } from './hooks';

type Props = {
  asMenuItem?: boolean;
  contentEntryId: number;
  localizedContentEntryId: number;
};

const DeleteAnswerModal: FC<Props> = ({
  asMenuItem,
  contentEntryId,
  localizedContentEntryId,
}) => {
  const { modal, openModal, disableDelete } = useDeleteAnswerHook(
    contentEntryId,
    localizedContentEntryId
  );

  if (asMenuItem) {
    return (
      <>
        <MenuItem
          onClick={openModal}
          sx={{
            display: 'flex',
            gap: 1,
            alignItems: 'center',
            color: 'danger.main',
          }}
          disabled={disableDelete}
        >
          <Delete />
          {'Delete'}
        </MenuItem>
        {modal}
      </>
    );
  }

  return (
    <>
      <Button
        variant="contained"
        color="danger"
        onClick={openModal}
        // can't delete something that's already deleted!
        disabled={disableDelete}
        startIcon={<Delete />}
      >
        {'Delete'}
      </Button>
      {modal}
    </>
  );
};

export default DeleteAnswerModal;
