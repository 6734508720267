import { FC } from 'react';
import { Button, Typography, Skeleton, MenuItem } from '@mui/material';
import Restore from '@mui/icons-material/Restore';
import useOzmoApiService from 'contexts/ozmo-api-service-context';
import useRestoreAnswer from 'services/hooks/use-restore-answer';
import getPlural from 'services/utils/get-plural';
import { useConfirmationModal } from 'components/modals';

type Props = {
  asMenuItem?: boolean;
  contentEntryId: number;
  localizedContentEntryId: number;
};

const RestoreAnswer: FC<Props> = ({
  asMenuItem,
  contentEntryId,
  localizedContentEntryId,
}) => {
  const api = useOzmoApiService();
  const {
    lce,
    isLoading: isLceLoading,
    handleRestoreAnswer,
  } = useRestoreAnswer(contentEntryId, localizedContentEntryId);

  const {
    all: referencedCollections,
    isLoading: isCollectionsLoading,
  } = api.Collection.getCollectionsByReferencedContentEntryId(contentEntryId, {
    enabled: contentEntryId !== null,
  });

  const modalLoading = isLceLoading || isCollectionsLoading;

  const { modal, openModal } = useConfirmationModal({
    onConfirm: handleRestoreAnswer,
    disableConfirmButtonOnClick: true,
    modalProps: {
      confirmButtonText: 'Restore answer',
      confirmInProgressText: 'Restoring…',
      title: 'You are about to restore an answer',
      message: modalLoading ? (
        <Skeleton />
      ) : (
        <>
          {'Restoring '}
          <Typography component="span" fontWeight="bold">
            {lce?.properties?.title}
          </Typography>
          {' will return the answer to the '}
          <Typography component="span" fontWeight="bold">
            {`${referencedCollections.length} ${getPlural(
              referencedCollections.length,
              'collection',
              'collections'
            )}`}
          </Typography>
          {
            ' it was originally referenced in. The answer will have the same status as before it was deleted.'
          }
        </>
      ),
    },
  });

  if (asMenuItem) {
    return (
      <>
        <MenuItem
          onClick={openModal as any}
          disabled={modalLoading}
          sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
        >
          <Restore />
          {'Restore answer'}
        </MenuItem>
        {modal}
      </>
    );
  }

  return (
    <>
      <Button
        variant="outlined"
        color="whitePrimary"
        onClick={openModal}
        disabled={modalLoading}
        startIcon={<Restore />}
      >
        {'Restore'}
      </Button>
      {modal}
    </>
  );
};

export default RestoreAnswer;
