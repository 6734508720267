import { FC } from 'react';
import { MenuItem, Icon, Button, Tooltip } from '@mui/material';
import Link from '@mui/icons-material/Link';
import { useCopyLink } from 'services/hooks/use-copy-link';

type Props = {
  asMenuItem?: boolean;
  contentType?: string;
  contentEntryId: number;
  language?: string;
};

const CopyButton: FC<Props> = ({
  asMenuItem,
  contentType,
  contentEntryId,
  language,
}) => {
  const [onClick, tooltipText] = useCopyLink(
    contentType,
    contentEntryId,
    undefined,
    false,
    language
  );

  if (asMenuItem) {
    return (
      <MenuItem
        onClick={onClick as any}
        sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
      >
        <Icon>
          <Link />
        </Icon>
        {'Copy Link'}
      </MenuItem>
    );
  }

  return (
    <Tooltip arrow title={tooltipText}>
      <Button
        onClick={onClick}
        aria-label={tooltipText}
        variant="outlined"
        color="whitePrimary"
        startIcon={<Link />}
      >
        {'Copy Link'}
      </Button>
    </Tooltip>
  );
};

export default CopyButton;
