import { FC } from 'react';
import SelectLanguageFromContentEntry from 'components/select-language-from-content-entry';
import { usePermissions } from 'components/permission-required';
import AutoSaveIndicator from 'components/autosave-indicator/autosave-indicator';
import { Typography } from '@mui/material';

import StickyHeader from './sticky-header';
import Title from './title';
import { useCurrentLocalizedContentEntry } from './hooks';

import { BaseHeaderProps } from '.';

type Props = BaseHeaderProps & {
  contentEntryId: number;
  language: string;
  titleField?: string;
};

const ContentEntryHeader: FC<Props> = ({
  contentEntryId,
  language,
  actions,
  actionButtons,
  onSelectLanguage,
  currentTab,
  setCurrentTab,
  tabLabels,
  titleField = 'title',
}) => {
  const {
    isLoading,
    isError,
    error,
    contentEntry,
    localizedContentEntry,
    title,
    handleTitleUpdate,
  } = useCurrentLocalizedContentEntry(
    contentEntryId,
    language,
    'contentEntry',
    titleField
  );

  const { checkPermission } = usePermissions();
  const hasEditCollectionPermission = checkPermission('edit_collection');
  const hasEditAnswerPermission = checkPermission('edit_answer');

  const {
    contentTypeId,
    manufacturers = [],
    devices = [],
    deviceTypes = [],
    operatingSystems = [],
    operatingSystemReleases = [],
    operatingSystemVersions = [],
  } = contentEntry || {};

  const isCollection = contentTypeId === 3;
  const topic = contentEntry?.topic?.slug;
  const subtitle = topic && (
    <Typography fontWeight={500} color="var(--color-neutral-seven)">
      {'Topic: '}
      <Typography component="span" color="inherit">
        {topic}
      </Typography>
    </Typography>
  );

  const languageSelector = (
    <SelectLanguageFromContentEntry
      copyContent
      contentEntryId={contentEntryId}
      onSelect={onSelectLanguage}
      languageShortCode={language}
      disableAddLanguage={
        (isCollection && !hasEditCollectionPermission) ||
        !hasEditAnswerPermission
      }
    />
  );

  return (
    <StickyHeader
      autoSaveIndicator={!isCollection && <AutoSaveIndicator />}
      title={
        <Title
          isCollection={isCollection}
          title={title}
          contentId={contentEntryId}
          onTitleUpdate={handleTitleUpdate}
          status={localizedContentEntry?.status ?? 'draft'}
        />
      }
      {...{
        isLoading,
        isError,
        error,
        actions,
        actionButtons,
        subtitle,
        manufacturers,
        devices,
        deviceTypes,
        operatingSystems,
        operatingSystemReleases,
        operatingSystemVersions,
        languageSelector,
        currentTab,
        setCurrentTab,
        tabLabels,
      }}
    />
  );
};

export default ContentEntryHeader;
