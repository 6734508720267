import { FC, ReactNode, MouseEventHandler, useCallback } from 'react';
import styled from 'styled-components';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  DialogProps,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const DialogBackdrop = styled('div')`
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(8px);
  position: fixed;
  inset: 0;
`;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    padding: 2rem;
    border-radius: 1.5rem;
    box-shadow: none;
  }

  .MuiDialogTitle-root {
    padding: 0 5rem 1.5rem 0;
  }

  .MuiDialogContent-root {
    padding: 0 0 2rem 0;
    overflow: ${({ scroll }) =>
      scroll === 'paper'
        ? 'inherit'
        : 'visible'}; /* allow for positioned content, like the attribute selector.
                          Very large content will scroll the whole modal instead of the body,
                          but there doesn't seem to be a really good way around it and not clip content. */
  }

  .MuiDialogActions-root {
    padding: 0 0.5rem 0 0;
  }

  .MuiButton-root {
    border-radius: 0.75rem;
  }
`;

const StyledCloseButton = styled(IconButton)`
  position: absolute;
  right: 2rem;
  color: var(--color-neutral-five);
`;

// We're omitting the DialogProps onClose because we want to define our own.
// The DialogProps one requires a "reason" arg.
export type Props = Omit<DialogProps, 'onClose'> & {
  title: string;
  disableBackdropClick?: boolean;
  actionChildren?: ReactNode;
  confirmInProgressText?: string;
  onClose: MouseEventHandler;
};

type DialogCloseReason = 'backdropClick' | 'escapeKeyDown';

const ModalBase: FC<Props> = (props) => {
  const {
    title,
    disableEscapeKeyDown,
    disableBackdropClick,
    children,
    actionChildren,
    onClose,
    ...dialogProps
  } = props;
  const handleClose = useCallback(
    (event: any, reason: DialogCloseReason) => {
      if (reason === 'backdropClick' && disableBackdropClick) return;
      if (reason === 'escapeKeyDown' && disableEscapeKeyDown) return;

      onClose && onClose(event);
    },
    [disableBackdropClick, disableEscapeKeyDown, onClose]
  );
  return (
    <StyledDialog
      {...dialogProps}
      slots={{
        backdrop: DialogBackdrop,
      }}
      disableEscapeKeyDown={disableEscapeKeyDown}
      onClose={handleClose}
    >
      <DialogTitle id={props['aria-labelledby']}>
        <Typography variant="h3" component="span">
          {title}
        </Typography>
        {onClose && (
          <StyledCloseButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </StyledCloseButton>
        )}
      </DialogTitle>
      <DialogContent aria-describedby={props['aria-describedby']}>
        {children}
      </DialogContent>
      {actionChildren && <DialogActions>{actionChildren}</DialogActions>}
    </StyledDialog>
  );
};

export default ModalBase;
